import React, { Component } from 'react';
import {  MDBCol ,MDBRow} from "mdbreact";
export default class FRadio extends Component {
    constructor(props){
      super(props)
  
      this.state = {
        is: false,
      }
    }
    render() {
      const field = this.props.field
      return (
          <div className="form-group">
          <label htmlFor={field.label} ><b>{field.label}</b></label>
          <MDBRow>
          {field.values.map((radio, i) =>([
            <MDBCol size="6" onClick={e =>  this.props.handlerFromInput(e, radio.value+'|' +field.name)} className={this.state.is?'selected-component': ''}  >
            <input
            type="radio"
            className=" input[type=checkbox]"
            name={field.name}
            value={radio.value}
            key={i}
            id={'F'+i + field.name}
            readOnly
          />
          <label>{radio.label}</label>
          </MDBCol>
          ]
          ))}
          </MDBRow>
        </div>
      );
    }
  }