import config from "../../config";
import axios from "axios";
function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token-rules")}`; 
 return axios
}


    function saveRol(body) {
        console.log('data :', body)
        const result =  axios_api(config.api+'/roles').post(config.api+'/roles',body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }



    function GetRolesByUser(id) {
        const result =  axios_api(config.api+'/roles/user/'+id).get(config.api+'/roles/user/'+id).then((res)=>{        
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
    
        return result;
        
    }


    function GetRolesById(id){
        const result =  axios_api(config.api+'/roles/'+id).get(config.api+'/roles/'+id).then((res)=>{        
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
    
        return result;
        
    }

    /** 
     * @param user id por user
     * @param form id formulary
     */
    function GetRolesByUserAndForm(user,form){
        const result =  axios_api(config.api+'/roles/user/form/'+user+'/'+form).get(config.api+'/roles/user/form/'+user+'/'+form).then((res)=>{        
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
    
        return result;
        
    }





  

    function deleteRolesById(id,idForm) {
        const result =  axios_api(config.api+'/roles/'+id+'/'+idForm).delete(config.api+'/roles/'+id+'/'+idForm).then((res)=>{
        return {'data':res.data,'ok':true};
        
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        return result;
        
    }


    export {
        saveRol,
        GetRolesById,
        GetRolesByUser,
        GetRolesByUserAndForm,
        deleteRolesById
    }