import React from "react";
import { Pie } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

class ChartsPage extends React.Component {
  constructor(props){
    super(props)
    this.state={
      dataPie: []
    }
  }
  componentWillReceiveProps(props){

  if(props.services){
    console.log('props.top5Services[0] :', props.services[0]);
    let labels = props.services[0];
    let data = props.services[1];
    this.setState({
      dataPie: {
        labels: labels,
        datasets: [
          {
            data: data,
            backgroundColor: [
              "rgb(240, 119, 6)",
              "#1a237e",
              "#FDB45C",
              "#949FB1",
              "#283593"
            ],
            hoverBackgroundColor: [
              "rgb(240, 119, 6)",
              "#1a237e",
              "#FFC870",
              "#A8B3C5",
              "#1a237e"
            ]
          }
        ]
      }
    })
  }
}

  render() {
    return (
      <MDBContainer className="padding">
      <Pie data={this.state.dataPie} options={{ responsive: true }} height={120} />
      </MDBContainer>
    );
  }
}

export default ChartsPage;