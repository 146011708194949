import React, { Component } from 'react';
import  RulesBar  from "../RulesBar/rulesbar";
import  RenderField  from "../RenderField/RenderField";
import { MDBRow, MDBCol} from "mdbreact";
import {fieldSelected, allFieldSelected, DelteSelected} from '../../Helpers/helpers';
import { Rules } from "../../data/index";

const {GetRulesById} = Rules
class RenderRules extends Component {

    constructor(props){
        super(props)
        this.state = {
          rules: '',
          ToInput: '',
          FromInput: '',
          idForm: '',
          message: false,
          dataOfRule: [] 
        }
        this.rules = this.rules.bind(this)
        this.ToInput = this.ToInput.bind(this)
        this.FromInput = this.FromInput.bind(this)
        this.ToInputByAllSelected = this.ToInputByAllSelected.bind(this)
      }
      rules(rule) {
        this.setState({
          rules: rule
        })
      }
      seachField(array, field){
        return array.filter(function (el) {
          return el === field
        });
      }
      ToInput(event,field){
        if(event.ctrlKey && document.getElementById('ToInput').value){
              let array = document.getElementById('ToInput').value
              let arrayField = array.split(',');
             if(arrayField.length > 1 && this.seachField(arrayField,field).length>0){
               return 
             }
            field = this.state.ToInput + (field ==null? '': ','+field);
            allFieldSelected(field)
          
        }else{
          fieldSelected(field)
        }
        this.setState({
          ToInput: field,
          rules: ''
        })
        
        //event.preventDefault();
      }

      ToInputByAllSelected(fields){
        this.setState({
          ToInput: fields,
          rules: ''
        })
      }
      FromInput(event,field){
       
        DelteSelected()
        this.setState({
          FromInput: field,
          ToInput: '',
          rules: ''
        })
        
        //event.preventDefault();
        
      }

      async componentDidMount(){
        const result = await GetRulesById(this.props.location.state.idRule)
        this.setState({
            dataOfRule: result.data.rules
        })
      }

    
  render() {
    return (
      <MDBRow style={{overflowY: 'scroll', height: window.innerHeight, padding: '2%'}}>
        <MDBCol size="8" className="" >
               <RenderField 
               ToInput={this.ToInput} 
               FieldToInput={this.state.ToInput} 
               FromInput={this.FromInput}
               FieldFromInput={this.state.FromInput}
               data={this.props.location.state.data}
               ToInputByAllSelected={this.ToInputByAllSelected} />
        </MDBCol>
        <MDBCol size="4"  className="border">
                <RulesBar 
                fieldToInput={this.state.ToInput} 
                fieldFromInput={this.state.FromInput}
                noRules={this.state.rules}
                rules={this.rules}
                data={this.props.location.state.data[0]}
                idForm={this.props.location.state.idForm}
                dataRule={this.state.dataOfRule}/>
        </MDBCol>
      </MDBRow>
    );
  }
}

export default RenderRules;