import React, { Component } from 'react';
import { MDBBadge,} from "mdbreact";
export class Badge extends Component {
    constructor(props){
        super(props)
        this.state={
            actualMask:'Ninguna',
        }
    }
      render() {
        return(
        <MDBBadge className={this.props.name} >
            <span className={this.props.name}>{'Máscara actual:'+this.state.actualMask}</span>
        </MDBBadge> 
        )
    } 
}
export default Badge;