import React, { Component } from 'react';
import config from '../../config';
import { MDBContainer, MDBRow, MDBCol, MDBBtn, MDBIcon, MDBInput} from 'mdbreact';
import { User,Router } from "../../data/index";
import { toast, ToastContainer } from 'react-toastify';
const { logiForToken } = User;
const {saveRouter} = Router
class login extends Component {

    constructor(props){
        super(props)
        this.state = {
          isOpen: false,
          user: '',
          pass: '',
          loading: false,
          isLogin: false,
          nameUser: '',
          activeItem: "1",
          modalForget: false,
          userLoggedIn:'',
          height: window.innerHeight, 
          width: window.innerWidth,
          colLeft: 7,
          colRight: 5,
          top: '7%',
          sizeImg: 300
          
      
        };
        this.updateDimensions = this.updateDimensions.bind(this);
        this.UserInput = this.UserInput.bind(this);
        this.PassInput = this.PassInput.bind(this);
      }

    UserInput(e){
        this.setState({
          user: e.target.value
        })
      }
      PassInput(e){
        console.log(e)
        this.setState({
          pass: e.target.value
        })
      }

    async Login(){
        this.setState({
          loading: true
        })
        
      
        
          const result = await logiForToken(this.state.user, this.state.pass)
          console.log('token :', result);
      
          if(result.ok){
            localStorage.setItem('token-rules', result.data.token_rules)
            localStorage.setItem('nameUser', result.data.user.user.firstname + ' ' + result.data.user.user.lastname)
            localStorage.setItem('idUser', result.data.user.user.id)
            localStorage.setItem('isLogin', true)
            localStorage.setItem('rol', 2)
            localStorage.setItem('userLoggedIn', result.data.user.user.email)
            let body = {
              idUser: result.data.user.user.id,
              router: "dash"
            }
            const router =  await saveRouter(body)
        
            this.setState({
              modal: false,
              isLogin:  result.data.user.auth,
              nameUser: result.data.fistname,
              rol: 2,
              userLoggedIn:result.data.email
            })
            window.location.href = '/home'
          }else{
            this.setState({
              loading: false,
            })
            toast.error('Este usuario no esta registrado en el portal administrativo',{
              position: toast.POSITION.BOTTOM_CENTER
            })
          }
      
      
      }
      logout(){
          this.setState({
            isLogin: false,
            nameUser:'',
            redirect: true
          })
          localStorage.setItem('token-rules','')
            localStorage.setItem('nameUser', '')
            localStorage.setItem('idUser', '')
            localStorage.setItem('isLogin', false)
            localStorage.setItem('rol', 0)
            localStorage.setItem('userLoggedIn', false)
      }
        componentDidMount() {
            // Additionally I could have just used an arrow function for the binding `this` to the component...
            window.addEventListener("resize", this.updateDimensions);
        }
        updateDimensions() {
            this.setState({
            height: window.innerHeight, 
            width: window.innerWidth
            });
            this.resize(this.state.width, this.state.height)
        }
        componentWillUnmount() {
            
            window.removeEventListener("resize", this.updateDimensions);
        }

        resize(value,height){
            if(value<1200){
                this.setState({
                    colLeft: 6,
                    colRight: 6,
                    top: '20%'
                })
            }
            if(value>1200){
                this.setState({
                    colLeft: 7,
                    colRight: 5,
                    top: '30%'
                })
            }
            if(value<1070){
                this.setState({
                    colLeft: 4,
                    colRight: 8
                })
            }
            if(height<700){
                this.setState({
                    top: '7%',
                    sizeImg: 300
                })
            }
            if(height>700){
                this.setState({
                    top: '30%',
                    sizeImg: 300
                })
            }
        }
    

    
    render() {
        
        return (
            <div  id="login" style={{height: 800}}>
            <ToastContainer/>
                <MDBRow className="overlay full-height" style={{ height: '100%', width: '100%'}}>
                    <MDBCol md={this.state.colLeft} style={{paddingTop: '20%',marginLeft:'2%'}} >
                    <h1 className="text-center" style={{color: 'white'}}><b>Bienvenido/a a la administración de formularios MITUR</b></h1>
                    <h5 className="text-center" style={{color: 'white'}}>Una herramienta para crear formularios dinámicos</h5>
                    </MDBCol>
                    <MDBCol md={this.state.colRight}  style={{ height: '100%', width: '100%',  right: '-100px', position: 'absolute', backgroundColor: 'white'}}>
                    <MDBRow style={{paddingRight:'20%', paddingLeft: '1%', paddingTop: '2%'}} between>
                    <label style={{color: 'grey', fontSize: 12}}>Administrador de formularios</label>
                    <label style={{color: 'grey', fontSize: 12}}>Versión 1.0</label>
                    </MDBRow>
                    <MDBRow style={{marginTop:this.state.top}}>
                        
                            <img className="img-fluid" style={{marginLeft: '60px', width: this.state.sizeImg}} src={require('../../img/Logo Mitur Final.png')}/>
                    </MDBRow>


                    <MDBRow>
                            <MDBCol md="9" style={{marginLeft: '5%',marginTop:'5%' }} >
                            
                                
                                <div className="grey-text">
                                <MDBInput
                                    label="Tu correo"
                                    group
                                    type="email"
                                    validate
                                    error="wrong"
                                    success="right"
                                    onChange={(e)=>this.UserInput(e)}
                                />
                                <MDBInput
                                    label="Tu contraseña"
                                    group
                                    type="password"
                                    validate
                                    onChange={(e)=>this.PassInput(e)}
                                />
                                </div>
                                <div className="text-center">
                                <MDBBtn className="btn btn-block" onClick={()=>this.Login()}   color="indigo">Ingresar</MDBBtn><br/>
                                <a href="#">Politicas de Privacidad</a><br/>
                                {this.state.loading&&(
                                    <div>
                                      <MDBIcon icon="spinner" spin size="2x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#1a237e'}}fixed />
                                      <label>Cargando....</label>
                                    </div>
                                    )}
                                <br/><br/><br/><br/><br/>

                                <label>Todos los derechos reservados a ConCentra ©2021</label>
                                </div>
                            
                            </MDBCol>
                        </MDBRow>
                    </MDBCol>
                </MDBRow>
            
            </div>
        );
    }
}

export default login;