import React, { Component } from 'react';
import {MDBDataTable, MDBIcon,MDBBtn} from "mdbreact";
import './css/tableMask.scss';
import {Form, Softexpert, Roles} from "../../data/index";
import {Backend} from '../../data/index';
import Mask from './mask';
import config from '../../config';
import {toast,ToastContainer} from 'react-toastify';
const {GetForms} = Softexpert
const {GetAllFormularyDataTable, seachFormulary, GetFormularyById} = Form
const {GetFormularyByServiceBK} = Backend
const {GetRolesByUserAndForm} = Roles
export class MasksTable extends Component {
    constructor(props){
        super(props)
        this.state = {
            AllForm:[],
            idForm:'',
            id:'',
            data2:[],
            serviceName:'',
            id:'',
            formName:'',
            serviceName:'',
            loading:true
          }
        this.toMaskFields = this.toMaskFields.bind(this)
    }
    async componentDidMount(){
        const data = await GetAllFormularyDataTable();
        console.log(data.data.Formulary , 'Formulary')
        let array = []
        try{
            for (const i of data.data.Formulary) {
              let element = {
                id: i.id,
                idForm: i.idForm,
                service: i.service_name,
                institution: i.institution_name,
                Máscara: <MDBBtn className="btn  btn-md text-white" color={config.color} onClick={()=> this.toMaskFields(i.idForm,i.id,i.service)}  
                
                >Asignar Máscaras</MDBBtn>
              }
              array.push(element)
              this.state.loading = false;
            }
          }catch(e){console.log(e)}
        const column = [
          {label:'id',field:'id',sort:'asc'},
          {label:'id del formulario',field:'idForm',sort:'asc'},
          {label:'Servicio',field:'service',sort:'asc'},
          {label:'Institución',field:'institution',sort:'asc'},
          {label:'Máscara',field:'Máscara',sort:'asc'}
        ]
        const result = {columns:column, rows: array}
        this.setState({
          AllForm: result,
        })
      }
    async toMaskFields(idForm,id){ 
      this.state.loading = true
      const roles = await GetRolesByUserAndForm(localStorage.getItem("idUser"),id)
    if(!roles.data.rol){
      this.setState({
        loading: 0
      })
       toast.error('Usted no tiene permiso para ver este formulario', {
        position: toast.POSITION.BOTTOM_CENTER
      })
      return
    }
      const data = await seachFormulary(idForm) 
      const data2 = await GetForms(idForm)
      /*const lastVersion = await GetLastversionFormByService(this.state.form.idservice);*/
      const dataBK = await GetFormularyById(id)
      console.log(data.data.Formulary[0].idForm, 'EL DATA DATA')
      try{
        if(data2.ok){
          this.setState({
            id:data.data.Formulary[0].id,
            data2:data2.data,
            id
          })
        } else{
          this.setState({
            id:data.data.Formulary[0].id,
            data2:[JSON.parse(JSON.parse(dataBK.data.Formulary[0].jsonForm)[0])],
            id
          })
        } 
      }catch(e){
          toast.warning('Error en el formulario', {
            position: toast.POSITION.BOTTOM_CENTER
            })
            this.state.loading= false
        } 
      console.log(this.state.data2.data,'look the Formulary')     
      console.log(this.state.data2,'look the Formulary')
      // console.log(data,data2,'look the Formulary')
      this.setState({
        serviceName:data.data.Formulary[0]['service_name'],
        formName:data.data.Formulary[0].idForm
      })
      console.log(this.state.serviceName,data.data.Formulary[0].id,'look the Formulary')
      this.props.history.push({
        pathname: '/Mask',
      })

      console.log(this.state.AllForm,'look the Formulary')
    }
    
    render() {
        return(
          this.state.data2.length<=0?(
            <div className="contentTable" style={{overflowY: 'scroll', height: window.innerHeight, padding: '2%'}}>
              <ToastContainer/>
            <h1>Formularios para Máscaras</h1>
            <br/>
              <MDBDataTable
                  striped
                  bordered
                  hover
                  data={this.state.AllForm}
              />
              {this.state.loading? (<MDBIcon icon="spinner" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />):''}
            </div>
            ):         
            (<Mask 
              data={this.state.data2}
              id={this.state.id}
              serviceName={this.state.serviceName}
              formName={this.state.formName}
              />) 
          ) 
    }
}
export default MasksTable;