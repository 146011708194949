import React, { Component } from 'react';
import Types from "prop-types";
import IdleTimer from 'react-idle-timer'
import { firebase } from "../../firebase";
import {  toast } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
class Page404 extends Component {
  constructor(props) {
    super(props)
    this.idleTimer = null
    this.onIdle = this._onIdle.bind(this)
    this.state = {
      isLogin: true
    }
  }
    static Types = {
        body: Types.object.isRequired
      }
  render() {
      const {body} = this.props
    return (
      <div className="App">
    
      {body}
     
      </div>
    );
  }

  logout(){
    firebase.auth().signOut().then((user)=>{
      toast.info('Caduco la session de usuario',{
        position: toast.POSITION.BOTTOM_CENTER
      })
     

    })
    .catch((error)=> {
      console.log('error :', error);
      toast.error('Ups! paso algo al salir de la cuenta',{
        position: toast.POSITION.BOTTOM_CENTER
      })
    });
  
  }
 
  _onIdle(e) {
    this.logout()
   
    console.log('cerro sesion')
  }
}

export default Page404;