import React, { Component } from 'react';
export default class TTime extends Component {
    componentWillReceiveProps(props){
    }
    render() {
      const field = this.props.field
      const i = this.props.id
      return (
          <div className="form-group" >
          <label htmlFor="formGroupExampleInput"><b>{field.label}</b></label>
          <input
            type="time"
            className="form-control"
            id="formGroupExampleInput"
            key={i}
            //* oldMask={field.Mask} */}
            name={field.name}
            disabled="disabled"
            placeholder="00:00"
          />
        </div>
      );
    }
  }
  
  //id={field.name}