import React, { Component } from 'react';
import {  MDBCol,
          MDBRow,
          MDBBtn,
          MDBContainer,
          MDBModal,
          MDBModalHeader,
          MDBModalBody,
          MDBTableHead,
          MDBTableBody,
          MDBTable
          } from "mdbreact";
import {    TFile,
            THeaders,
            TRadio,
            TSelect,
            TSubTitle,
            TText,
            TTextarea,
            TTime,
            TDate } from "../index";  
            
import Fields from '../../formConfigurations/fields';
export default class TGrid extends Component {
    constructor(props){
      super(props)
      this.state = {
        modal: false
      }
      
    }
    
    toggle =  () => {
        this.setState({
          modal: !this.state.modal
        });
        if(!this.state.modal){
            this.props.config&&this.props.arrayGrid([])
        }
        if(this.props.config){
           console.log('hola mundo');
           
             this.props.selectedCheck(this.props.json,'hola')
        }
      }
    componentWillReceiveProps(props){
    
    
    
    }


    fieldsGrid(field){
        field.name = this.props.field.name+'_'+field.name
        return field
    }
    render() {
      const field = this.props.field
      const i = this.props.id

      if(this.props.config){
          return(
            <MDBContainer>
            <MDBBtn onClick={this.toggle}>{'GRID: ' +field.label}</MDBBtn>
            <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                <MDBModalHeader>{field.label}</MDBModalHeader>
                <MDBModalBody>
                
                <MDBRow >
                <MDBTable key={i} bordered>
                    <MDBTableHead>
                        <tr>
                            <th>Campo</th>
                            <th>Requerido</th>
                            <th>Oculto</th>
                            <th>Deshabilitado</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        {field.fields.map((field, i)=>(
                            <tr>
                                <td><Fields config={true} field={field}/></td>
                                
                                <td>
                                    <div className="form-group">
                                        <input
                                        type="checkbox"
                                        id={ field.name+'required'}
                                        hidden={field.type==='header' || field.type==='subtitle'|| field.type==='grid'? true:false}
                                        onClick={e => this.props.create(field.name,this.props.field.type,this.props.field.name) }
                                        />
                                    </div>
                                </td>

                                <td>
                                    <div className="form-group">
                                        <input
                                        type="checkbox"
                                        id={ field.name+'hidden'}
                                        onClick={e => this.props.create(field.name,this.props.field.type,this.props.field.name) }
                                        />
                                    </div>
                                </td>

                                <td>
                                    <div className="form-group">
                                        <input
                                        type="checkbox"
                                        id={ field.name+'disabled'}
                                        hidden={field.type==='header' || field.type==='subtitle' || field.type==='grid'? true:false}
                                        onClick={e => this.props.create(field.name,this.props.field.type,this.props.field.name) }
                                        />
                                    </div>
                                </td>
                            </tr>
                        ))}
                    </MDBTableBody>
                </MDBTable>
                    
                </MDBRow>
                </MDBModalBody>
                
            </MDBModal>
            </MDBContainer>
          )
      }
      return (
          <div id={i} className="form-group" >
         <MDBContainer>
        <MDBBtn onClick={this.toggle}>{'GRID: ' +field.label}</MDBBtn>
        <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
            <MDBModalHeader onClick={(e) => this.props.ToInput(e,field.name)} >{field.label}</MDBModalHeader>
            <MDBModalBody>
            
            <MDBRow >
                {field.fields.map((field, i)=>(
                    field.type === 'text' ? (
                            <MDBCol size="6" className="cursor"  onClick={(e) => this.props.ToInput(e,field.name)}>
                                <TText key={i} id={i + field.label} fieldToInput={this.props.FieldToInput} field={field}/>
                            </MDBCol>
                    )
                    :
                    field.type === 'radio-group' ? (
                        <MDBCol size="6" className="cursor">
                        <TRadio key={i} fieldToInput={this.props.FieldToInput}  handlerToInput={ this.props.ToInput} id={i + field.label} field={field}/>
                        </MDBCol>
                    )
                    :
                    field.type === 'select'   ? (
                        <MDBCol size="6" className="cursor" onClick={(e) => this.props.ToInput(e,field.name)}>
                        <TSelect key={i} id={i + field.label} field={field}/>
                        </MDBCol>
                    )
                    :
                    field.type === 'file' ? (
                        <MDBCol size="6" className="cursor" onClick={(e) => this.props.ToInput(e,field.name)}>
                        <TFile key={i} id={i + field.label} field={field}/>
                        </MDBCol>
                    )
                    :
                    field.type === 'header' ? (
                        
                        
                        <MDBCol size="12" className="cursor" onClick={(e) => this.props.ToInput(e,field.name)}>
                        <hr/>
                        <THeaders key={i} id={i + field.label}  handlerToInput={ this.props.ToInput} field={field}/>
                        </MDBCol>
                    )
                    :
                    field.type === 'textarea' ? (
                        <MDBCol size="6" className="cursor"  onClick={(e) => this.props.ToInput(e,field.name)}>
                            <TTextarea key={i} id={i + field.label} fieldToInput={this.props.FieldToInput} field={field}/>
                        </MDBCol>
                    )
                    :
                    field.type === 'time' ? (
                        <MDBCol size="6" className="cursor"  onClick={(e) => this.props.ToInput(e,field.name)}>
                            <TTime key={i} id={i + field.label} fieldToInput={this.props.FieldToInput} field={field}/>
                        </MDBCol>
                    )
                    :
                    field.type === 'date' ? (
                        <MDBCol size="6" className="cursor"  onClick={(e) => this.props.ToInput(e,field.name)}>
                            <TDate key={i} id={i + field.label} fieldToInput={this.props.FieldToInput} field={field}/>
                        </MDBCol>
                    )
                    :
                    field.type === 'subtitle' && (
                        <MDBCol size="12" className="cursor" onClick={(e) => this.props.ToInput(e,field.name)}>
                        <TSubTitle key={i} id={i + field.label} field={field}/>
                        </MDBCol>
                    )
                    
                    
                ))}
            </MDBRow>
            </MDBModalBody>
            
        </MDBModal>
        </MDBContainer>
        </div>
      );
    }
  }
  
  