import React, { Component } from 'react';
import {  MDBIcon } from "mdbreact";
export default class FText extends Component {
    componentWillReceiveProps(props){
    }


    onCLickRule(e,name, ){
      if(e.target.getAttribute("data-selected")==='false'){
        this.props.handlerFromInput(e,name)
        e.target.style="color: #00695c"
        e.target.setAttribute('data-selected', 'true')
        return
      }

      if(e.target.getAttribute("data-selected")==='true'){
        this.props.handlerFromInput(e,"")
        e.target.style="color: black"
        e.target.setAttribute('data-selected', 'false')
        return
      }
      console.log('e.target :', e.target.getAttribute("data-selected"));
    }
    render() {
      const field = this.props.field
      const i = this.props.id
      return (
          <div className="form-group" >
          <label><b>{field.label}</b> <span id={'rule'+field.name}></span></label><br/>
          <MDBIcon icon="arrow-alt-circle-left" data-selected={false} id={'I|'+ field.name} onClick={e =>  this.onCLickRule(e,'I|'+ field.name)}/> <MDBIcon icon="arrow-alt-circle-right" id={'O|'+ field.name} data-selected={false} onClick={e =>  this.onCLickRule(e,'O|'+ field.name)} />
          <input
            type="text"
            className="form-control"
            id="formGroupExampleInput"
            key={i}
            name={'F'+field.name}
            placeholder={'id: '+ (field.name)}
            disabled="disabled"
          />
        </div>
      );
    }
  }
  
  