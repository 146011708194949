import React, { Component } from 'react';
import {  MDBCol ,MDBRow} from "mdbreact";
export default class TCheckbox extends Component {
    constructor(props){
      super(props)
  
      this.state = {
        is: false,
      }
    }
 
    render() {
      const field = this.props.field
      return (
          <div className="form-group">
          
          <MDBRow>
          {field.values.map((radio, i) =>([
            <MDBCol size="6" onClick={e =>  this.props.handlerToInput(e, field.name)} className={this.state.is?'selected-component': ''}  >
            <input
            type="checkbox"
            name={field.name}
            value={radio.value}
            key={i}
            id={i + field.name}
            readOnly
            disabled="true"
          />
          <label>{radio.label}</label>
          </MDBCol>
          ]
          ))}
          </MDBRow>
        </div>
      );
    }
  }