import React, { Component } from 'react';
import {MDBRow, MDBCol,MDBBtn,MDBIcon, MDBModal,MDBTable,
MDBTableHead, MDBModalBody, MDBModalHeader, MDBModalFooter } from "mdbreact";
import  CardText  from "../Utilities/CardText";
import RuleBox from "../RulesBar/ruleBox";
import  Resumen  from "./ResumenCard";
import ReactJson from 'react-json-view'
import './rulesbar.css';
import {Rules} from '../../data/index';

import { nameRule } from "../../Helpers/helpers";
import {  toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';

const {saveRules,GetLastversionRule} = Rules
class rulesbar extends Component {
  constructor(){
    super()
   this.state = {
    modal: '',
    rules: [],
    json: {},
    ViewCodeRule: false,
    ViewJSON: false,
    VIewRule: true,
    dataForViewRule: [],
    loading: false,
    RuleSeleted: null,
    ruleForJson: [],
    dontRender: false,
    enter:true
   }
  this.RuleSeleted = this.RuleSeleted.bind(this);
  this.handlerEnterSave =  this.handlerEnterSave.bind(this);
  }


  RuleSeleted(rule){
    this.setState({
      RuleSeleted: rule,
      dontRender: false
    })
  }
  toggle = () => {
    this.setState({
      modal: !this.state.modal
    });
  }

  render() { 
    //console.log('this.saaasddsddastate :', this.props.dataRule);
    return (
        <div>
        <ToastContainer/>
      <MDBRow ><MDBCol><CardText color="rgb(201, 201, 201)" text="CAMPO"/></MDBCol></MDBRow>
      <br/>
      <MDBRow >
        <MDBCol size="0"></MDBCol>
        <MDBCol size="12">
        <label  ><b>Desde</b></label>
          <input
            type="text"
            className="form-control"
            id="FromInput"
            value={this.props.fieldFromInput}
            readOnly
          /> 
        </MDBCol>
        <MDBCol size="0"></MDBCol>
      </MDBRow>
      <br/>
      <MDBRow >
        <MDBCol size="0"></MDBCol>
        <MDBCol size="12">
        <label ><b>Para</b></label>
          <input
            type="text"
            className="form-control"
            id="ToInput"
            value={this.props.fieldToInput}
            disabled={this.props.fieldFromInput? false: true}
            readOnly
          /> 
        </MDBCol>
        <MDBCol size="0"></MDBCol>
      </MDBRow>
      <br/><br/>
      <MDBRow > <MDBCol><CardText color="rgb(201, 201, 201)" text="REGLAS"/></MDBCol></MDBRow>
      <br/>
      
        {
          this.props.fieldToInput && (
            <MDBRow size="12">
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={'0'} name="Ocultar"/>
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={1} name="Visualizar"/>
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={2} name="Deshabilitar"/>
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={3} name="Habilitar"/>
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={4} name="Requerido"/>
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={5} name="Click"/>   
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={6} name="No Requerido"/>   
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={7} name="Limpiar"/>   
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={8} name="Cambiar a CEDULA"/>
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={9} name="Cambiar a PASAPORTE"/>
            <RuleBox noRules={this.props.noRules} RuleSeleted={this.RuleSeleted} handlerRule = {this.props.rules} rule={10} name="Cambiar a RNC"/>       
            </MDBRow>
          )
          
        }
                

      <br/><br/>
      <MDBRow size="12" ><MDBCol> <CardText color="rgb(201, 201, 201)" text="RESUMEN"/></MDBCol></MDBRow>
      <br/>
      <MDBRow size="12" > 
                <MDBCol size="0"></MDBCol>
                <MDBCol size="12">
                  <Resumen 
                   noRules={this.props.noRules}
                   fieldToInput={this.props.fieldToInput}
                   fieldFromInput={this.props.fieldFromInput}
                   RulesFull={this.RulesFull}
                   ruleSelected={this.state.RuleSeleted}
                   data={this.props.dataRule}
                   dontRender={this.state.dontRender} />
                </MDBCol>
                <MDBCol size="0"></MDBCol>
      </MDBRow>
      <MDBRow size="12" className="padding" > 
                <MDBCol size="0"></MDBCol>
                <MDBCol size="12">
                  <MDBBtn size="lg"  className="btn-block danger-color" 
                  onClick={ e => this.SaveJson(e)} 
                  >
                  Generar Regla
                  {this.state.loading&&(
                            <MDBIcon icon="spinner" spin size="1x" style={{color: '#FFFFF'}} fixed />
                        )}
                  </MDBBtn>
                </MDBCol>
                <MDBCol size="0"></MDBCol>
      </MDBRow>
      {/*modal==========================================================RULES*/}
      <MDBModal  size="fluid" isOpen={this.state.modal} toggle={this.toggle}>
        <MDBModalHeader toggle={this.toggle}>RESUMEN RULES</MDBModalHeader>
        <MDBModalBody>
          
        <div rows="15">
          <div style={{display:this.state.ViewCodeRule? 'block': 'none'}}>
          {this.state.rules.length>0&&
              this.state.rules.map((data)=>(
                <MDBRow className=" padding block-example border-bottom border-default" >
                <MDBCol size="2">
                  <b>{data.split('=')[0]}</b>
                </MDBCol>
                <MDBCol size="10" >
                  <textarea rows="5" cols="8" className="form-control" readonly>
                  {data.split('=')[1]}
                  </textarea>
                </MDBCol>
                </MDBRow>
                
             
             
              ))}
              </div>
              <div className="scrollinRules" style={{display:this.state.ViewJSON? 'block': 'none'}}>
              <ReactJson  src={this.state.json} />
              </div>
              <div className=" padding scrollinRules" id="VIewRule" style={{display:this.state.VIewRule? 'block': 'none'}}>
              {this.state.dataForViewRule.map((data)=>(
                <div >
                  <br/><br/>
                  <label>{data[0]}</label>
                  <MDBRow >
                    <MDBCol ></MDBCol>
                    <MDBCol  size="10">
                    
                      <MDBTable className="padding block-example border-bottom border-default">
                      <MDBTableHead color="default-color" textWhite>
                        <tr>
                          <th>Regla</th>
                          <th>Campo</th>
                        </tr>
                        </MDBTableHead>
                        {data[1].map((value)=>(
                          <tr>
                            <td><b>{nameRule(value.rule)}</b></td>
                            <td><b>{value.field}</b></td>
                          </tr>
                        ))}
                      </MDBTable>
                    </MDBCol>
                    <MDBCol  ></MDBCol>
                   
                </MDBRow>
                
                </div>
              ))}
                
              </div>
              
               
        
        </div>
        <MDBBtn size="sm" outline color="default" onClick={(e)=> this.setState({ViewCodeRule: false, ViewJSON: false, VIewRule: true})}>Reglas</MDBBtn>
          <MDBBtn size="sm" outline color="default" onClick={(e)=> this.setState({ViewCodeRule: true, ViewJSON: false, VIewRule: false})}>Codigo de Reglas</MDBBtn>
          <MDBBtn disabled size="sm" outline color="default" onClick={(e)=> this.setState({ViewCodeRule: false, ViewJSON: true, VIewRule: false})}>JSON</MDBBtn>
          
        </MDBModalBody>
        <MDBModalFooter>
          <MDBBtn 
            onClick={()=> this.saveAllRules()}
            color="default">
          Guardar Regla</MDBBtn>
        </MDBModalFooter>
       
      </MDBModal>
      </div>
    );
  }


  checkExistRule(rules){
    let uniqueArray = rules.filter(function(item, pos) {
      if(rules.indexOf(item) !== pos){
        toast.info('Eliminamos esta regla repetida: '+ item, {
          position: toast.POSITION.BOTTOM_CENTER
        });
      }
      return rules.indexOf(item) === pos;
    })

    return uniqueArray;
  }
  CreateRule(){
    let fields = '';
    let rules = ''
    let RuleField = []
    let ConvertRules = []
    let dataForViewRule =[]
    let Resumen = document.getElementById('Resumen').innerText;

    let field = Resumen.split(';')
        field = this.checkExistRule(field)
    for (let i = 0; i < field.length; i++) {
      if(field[i] !== " "){

        let fieldFrom = field[i].split('=')
       
        if(fieldFrom[1] && fieldFrom[0]){
          ConvertRules[fieldFrom[0].trim()] += ' '+fieldFrom[1].trim()+';'
         
        }
      }

    }

    for (const key in ConvertRules) {
     
      let rule = ConvertRules[key].split(';');
     
      for (let i = 0; i < rule.length-1; i++) {
        let element = rule[i].split(':')
        if(i === rule.length-2){
          rules += element[0].trim() 
          fields += element[1].trim()    
        }else{
          rules += element[0].trim() +','
          fields += element[1].trim()  + ','  
        }
           
      }
      
      
      
      
      let arrayRule = this.dataForRule(key,rules.split(','), fields.split(','))
      dataForViewRule.push(arrayRule);
      let data =  key + '='+rules +':'+fields
      RuleField[key] = data.replace(/undefined /g,'');
      rules='';
      fields = '';
    }
    this.setState({
      dataForViewRule: dataForViewRule
    })
    

    return RuleField;
 
  }

  dataForRule(nameField,rules, field){
    
    let dataForViewRule = []
    for (const key in rules) {
      
        let element = {rule:rules[key].replace(/undefined /g,''),field:field[key]};
        dataForViewRule.push(element) 
    }
    return [''+nameField , dataForViewRule]
  }
   async handlerEnterSave(e){
      if(e.key === 'Enter' && this.state.enter === true){
        this.saveAllRules()
      }
      this.setState({
        enter:false
      })
      console.log(this.state.enter)
    }
    SaveJson(){
      document.addEventListener('keydown', this.handlerEnterSave);
      this.setState({
        dontRender: true,
        enter:true
      })
     let jsonRule = [];
    let rule = this.CreateRule();
    for (const key in rule) {
      jsonRule.push(rule[key]);
 
    }
    this.setState({
      modal: true,
      rules:  jsonRule,
      loading: false,
      ruleForJson: rule
    });
  }
 async saveAllRules(){
   console.log('this.props.idForm :', this.props.idForm);
 let lastVersion = await GetLastversionRule(this.props.idForm)
 let version = lastVersion.data.err?0:lastVersion.data.rules;

 
  let rules = {
    rule:  this.state.rules.join('&'),
    idForm: this.props.idForm,
    version:  version + 1 
  }
 console.log('rules :', rules);
 
  const result =  await saveRules(rules);
  console.log('result save rule:', result);
  this.setState({
    modal: false
  })
  try{
      if(result.data.rules.id && result.ok){
        await toast.success('Se Guardo Correctamente la regla con la version: '+ rules.version, {
          position: toast.POSITION.BOTTOM_CENTER
        });
    
      }else{
        await toast.error('Error al guardar la regla!', {
          position: toast.POSITION.BOTTOM_CENTER
        });
  }
}catch(e){
  await toast.success('Se Guardo Correctamente la regla con la version: '+ rules.version, {
    position: toast.POSITION.BOTTOM_CENTER
  });
}

}
}

export default rulesbar;