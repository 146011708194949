import React, { Component } from 'react';
import { MDBRow, MDBCol,MDBBtn,MDBView, MDBIcon,MDBMask } from "mdbreact";
class options extends Component {
    render() {
        console.log('this.props :', this.props);
        return (
            <div><br/><br/>
            <h1><b>Que necesitas gestionar?</b></h1><hr/><br/><br/><br/><br/>
                <MDBRow around>
                        <MDBCol md="4">
                            <MDBView onClick={()=> this.props.history.push('/admin')} >
                                <div style={{backgroundColor: '#1a237e ', width: 300, borderRadius: 40, padding: 10}}>
                                    <MDBIcon style={{fontSize: 200, color: 'white'}} icon="clipboard-list"  /><br/><br/>
                                    <h3 style={{color: 'white'}}><b>Gestion de formulario</b></h3>
                                </div>
                                <MDBMask style={{ width: 300, borderRadius: 40, padding: 10}} className="flex-center" overlay="orange-strong ">
                                    
                                </MDBMask>
                            </MDBView>
                        </MDBCol>
                        <MDBCol md="4">
                            <MDBView onClick={()=> this.props.history.push('/users')}>
                                <div style={{backgroundColor: '#1a237e ', width: 300, borderRadius: 40, padding: 10}}>
                                    <MDBIcon style={{fontSize: 200, color: 'white'}} icon="users" /><br/><br/>
                                    <h3 style={{color: 'white'}}><b>Gestion de usaurios</b></h3>
                                </div>
                                <MDBMask style={{ width: 300, borderRadius: 40, padding: 10}} className="flex-center" overlay="orange-strong ">
                                    
                                </MDBMask>
                            </MDBView>
                        </MDBCol>
                </MDBRow>
            </div>
        );
    }
}

export default options;