import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
import './css/index.scss';
import './css/tableMask.scss';
import { array } from 'prop-types';

export default class SimpleModalHeader extends Component {
    constructor(props){
        super(props)
    }
    render(){
        return(
        <h4>
            <b>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            {this.props.text}
            </b>
        </h4>
        );
    }

}