import config from "../../config";
import axios from "axios";
function axios_api(api) {
  axios.defaults.baseURL = api;
  axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
  axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
  return axios
}
async function CreateTemplate(content , title , version){
  const result = await axios_api(config.api+'/MailTemplate')
  .post(config.api+'/MailTemplate',{templateHTML:content,templateName:title,templateVersion:version,})
  .then((res)=>{
    return {'data':res.data,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false}
  });
  return result;
}
async function GetAllTemplate() {
  const result = await axios_api(config.api+'/MailTemplate')
  .get(config.api+'/MailTemplate')
  .then((res)=>{
    return {'data':res.data.mails,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false}
  });
  return result;
}
async function GetTemplateById(id) {
  const result = await axios_api(config.api+'/MailTemplate/'+id)
  .get(config.api+'/MailTemplate/'+id)
  .then((res)=>{
    return {'data':res.data.mails,'ok':true};
    }).catch((error)=>{
    return {'data':error,'ok':false}
  });
  return result;
}
export {
  CreateTemplate,
  GetAllTemplate,
  GetTemplateById
}