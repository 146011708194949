import React, { Component } from 'react';
import { MDBRow,MDBBtn, MDBCol} from "mdbreact";
import {Mails} from "../../data/index";

import 'react-toastify/dist/ReactToastify.css';
import './mails.css';
import CKEditor from '@ckeditor/ckeditor5-react';
import DecoupledEditor from '@ckeditor/ckeditor5-build-classic/';

const {CreateTemplate, GetAllTemplate, GetTemplateById} = Mails
class Main extends Component {
  constructor(props){
    super(props)
    this.state = {
      content:'',
      title:'',
      version:0,
      data:'',
      dataTemplates:[],
      templateHTML: '',
      Template:''
    }
  }
  async saveTemplate(){
    let content = document.getElementsByClassName("ck-content")[0].innerHTML;
    let title = document.getElementById("title");
    console.log(title.value, content)
    content === '' || content === null? alert("El documento no tiene contenido") :  await CreateTemplate(content,title.value, this.state.version);
  }
   async getAllTemplates(){
    this.setState({
      dataTemplates: await GetAllTemplate()
     })
  }
  async getTemplateById(e){
    let id= e.target.value
    this.setState({
      Template: await GetTemplateById(id)
     })

     this.state.content = '';
     try{this.state.content = this.state.Template['data']['templateHTML']}
     catch(e){console.log(e)};
  }
  componentDidMount(){
    this.getAllTemplates()
    DecoupledEditor
    .create( document.querySelector( '#editor' ))
    .then(editor => {
        const toolbarContainer = document.querySelector( '#toolbar-container' );
        toolbarContainer.appendChild( editor.ui.view.toolbar.element );
    })
    .catch( error => {
        console.error( error );
    });
  }
  /*****************---- Enviar Configuracion  ----******************/
  render() {
    return (
        <MDBRow>
          <MDBCol size="9" >
          <MDBRow><br></br></MDBRow>
          <MDBRow className="marginSelected">
            <div className="CKEditor">
                  <h3>Manejador de Plantillas para Correo</h3>
                  <CKEditor
                    onInit={ editor => {
                        console.log( 'Editor is ready to use!', editor );
                        // Insert the toolbar before the editable area.
                        editor.ui.getEditableElement().parentElement.insertBefore(
                            editor.ui.view.toolbar.element,
                            editor.ui.getEditableElement()
                        );
                    } }
                    editor={ DecoupledEditor }
                    onChange={ ( event, editor ) => {
                      const data = this.state.content
                      this.setState({
                        content: data
                      })
                  } }
                />
            </div>
          </MDBRow>
          </MDBCol >
          <MDBCol size="3"  className="border">
            <label><b>...:::Nueva Plantilla:::...</b></label>
            <hr></hr>
            <label><h6>Titulo: </h6></label><input type="text"  className="form-control" id="title"/>
              <MDBBtn color="default" id="Add" onClick={(e) =>this.saveTemplate(this.state.content, this.state.title)}>Guardar</MDBBtn>
            <br></br>
            <br></br>
            <label><b>...:::Cargar Plantilla:::...</b></label>
            <hr></hr>
              <select onChange={(e) =>this.getTemplateById(e)} className="form-control">
              <option selected value="0">Escoja una plantilla</option>
              { this.state.dataTemplates.ok===true ? 
                this.state.dataTemplates['data'].map((value) => 
                <option value={value.id}> - {value.templateName}</option>
                ): <option value="1">No hay plantillas disponibles</option>
              }
              </select>        
          </MDBCol>
        </MDBRow>
    ) 
  } 
}
export default Main;