import React, { Component } from 'react';
import {MDBCol,MDBIcon,MDBContainer,MDBModal,MDBModalHeader,MDBModalBody,MDBView,MDBMask} from "mdbreact";
import CardText from '../Utilities/CardText'; 
import { Redirect } from 'react-router-dom';
import { Rules  } from "../../data/index";
import config from '../../config';
const {GetRulesById} = Rules
class RulesListBox extends Component {
    constructor(props){
        super(props)
        this.state = {
            shadow: '',
            newRule: false,
            rules:'',
            modal: ''
        }
    }
    async  viewRule(){
      const result = await GetRulesById(this.props.rules.id)
        this.setState({
          modal: true,
          rules: result.data.rules[0].rule
        })
      }

      toggle = () => {
        this.setState({
          modal: !this.state.modal
        });
      }
  render() {
    if(this.state.newRule){
      return(
        <Redirect to={{
            pathname: '/render',
            state: {data: this.props.data, idForm: this.props.idForm, idRule: this.props.rules.id }
          }} 
        />
      )
    }
    return (
          <MDBCol  size="3" style={{padding: '1%'}}>
          <MDBIcon onClick={()=>this.viewRule()} icon="eye" style={{color: '#1a237e'}} size="2x" className=" cursor"></MDBIcon>
        <MDBView onClick={()=> this.setState({newRule: true})}>
            <div style={{backgroundColor: '#1a237e ', width: 300, borderRadius: 40, padding: 10}}>
                <MDBIcon style={{fontSize: 140, color: 'white'}} far icon="edit" /><br/><br/>
                <h5 style={{color: 'white'}}><b> version: {this.props.rules.versionRule}</b></h5>
            </div>
            <MDBMask style={{ width: 300, borderRadius: 40, padding: 10}} className="flex-center" overlay="orange-strong ">
            <label   ><b>creada el:</b> {this.props.rules.createdAt.split('T')[0] } - {this.props.rules.createdAt.split('T')[1].substring(0, 5) }  </label>

            </MDBMask>
        </MDBView>

        {/**
          ==============================================MODAL IMPORT RULE==========================
        */}        
          <MDBContainer>
                <MDBModal isOpen={this.state.modal} toggle={this.toggle}>
                  <MDBModalHeader toggle={this.toggle}>Regla version no. {this.props.rules.versionRule}</MDBModalHeader>
                  <MDBModalBody>
                    <textarea disabled className="form-control" rows="4">
                    {this.state.rules}
                    </textarea>
                  </MDBModalBody>
                </MDBModal>
              </MDBContainer>
            </MDBCol>
    );
  }
}

export default RulesListBox;