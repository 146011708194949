import React, { Component } from 'react';
export default class FSelect extends Component {
  
    componentWillReceiveProps(props){
      /*
      if(props.fieldFromInput){
        for (let index = 0; index < document.getElementsByTagName("select").length; index++) {
          document.getElementsByTagName("select")[index].style = ""
          
        }
        
       // document.getElementById(props.fieldFromInput.split("|")[1]).style = "box-shadow: 5px 5px 10px grey;"
      }
      */
      
    }
    render() {
      const field = this.props.field
      console.log('field :', field);
      const i = this.props.id
      return (
          <div className="form-group">
          <label htmlFor={field.label} >{field.label}</label>
          <select   key={i} id={ 'F'+field.name} className="browser-default custom-select" onChange={(e) => this.props.handlerFromInput(e, e.target.value +'|'+field.name)} >
          <option  key={i} value={'00'}>seleccione</option>
          {field.values&&  field.values.map((option, i)=>(
            <option  key={i} value={option.value}>{option.label}</option>
          ))}
            
            
          </select>
        </div>
      );
    }
  }

