import React, { Component } from 'react';
import { MDBRow,MDBDataTable, MDBCol,MDBIcon,MDBCard,MDBProgress,MDBAlert,MDBModal,
MDBModalBody, MDBModalHeader,MDBBtnGroup, MDBModalFooter,MDBBtn, Modal,MDBTable, 
MDBTableBody, MDBTableHead} from "mdbreact";
import {  toast, ToastContainer } from 'react-toastify';
import config from '../../config';
import { Form, User,Roles,Router } from "../../data/index";

const {GetAllFormularyDataTable, updateFormularyById, deleteFormularyById } = Form
const {allUsers,} = User
const { saveRol, deleteRolesById,GetRolesByUser,GetRolesByUserAndForm} = Roles
const {saveRouter,getRouterByUser,deleteRouter} = Router
class Users extends Component {
    constructor(props){
        super(props)
        this.state = {
            AllUsers: [],
            loading: false,
            modal: false,
            modalRouter: false,
            idUser:'',
            AllForm: []
        }
        
        this.toggle = this.toggle.bind(this);
        this.toggleRouter = this.toggleRouter.bind(this);
    }

    toggle(id, name, last) {
        this.setState({
            modal: !this.state.modal,
            name: name + ' '+last,
            idUser:id
        })

        if(!this.state.modal) {
            this.showDataTableForm();
        }
    }

    async toggleRouter(id, name, last){
        const result = await getRouterByUser(id)
        console.log('result :', result);
        this.setState({
            modalRouter: !this.state.modalRouter,
            name: name + ' ' + last,
            idUser:id
        })

        if(result.data.routers.length>0){
            await this.sleep(1)
            let data = result.data.routers;
            for (const key in data) {
                document.getElementById(data[key].router).checked = true;
            }
        }
    }

    componentDidMount() {
        this.showDataTable();
    }

    async showDataTable(){
        this.setState({
            loading: true
        })

        const users = await allUsers();
        let data = users.data
        let array = []

        try {
            for (const i of data) {
                let element = {
                    id: i.id,
                    Nombre: i.firstname,
                    Apellido: i.lastname,
                    Correo: i.email,
                    Accion: [
                        <MDBBtn color="primary" className="btn-sm" onClick={ e =>this.toggle(i.id,i.firstname,i.lastname)}>Formularios</MDBBtn>,
                        <MDBBtn color="success" className="btn-sm" onClick={ e =>this.toggleRouter(i.id,i.firstname,i.lastname)}>Rutas</MDBBtn>
                    ]
                }
                array.push(element)
            }
        } catch(e) {console.log(e)}

        const column = [
            {label:'Id',field:'id',sort:'asc'},
            {label:'Nombre',field:'Nombre',sort:'asc'},
            {label:'Apellido',field:'Apellido',sort:'asc'},
            {label:'Correo',field:'Correo',sort:'asc'},
            {label:'Acción',field:'Accion',sort:'asc'}
        ]
        const result = {columns:column, rows: array}

        this.setState({
            AllUsers: result,
            loading: false
        })
    }

    sleep(ms) {
        return new Promise(resolve => setTimeout(resolve, ms));
    }

    async checkPermision(roles) {
        for (let key in roles) {
            await this.sleep(1);
            let check = document.getElementById('check'+roles[key].idForm);
            if (check) {
                check.checked = true;
            }
        } 
    }

    async showDataTableForm(){
        this.setState({
            loading: true
            })
        const data = await GetAllFormularyDataTable();
        const roles = await GetRolesByUser(this.state.idUser)
        console.log('roles :', roles);
        let array = []
        await this.sleep(1000);
        try{
            for (const i of data.data.Formulary) {
            let element = {
                id: i.id,
                idForm: i.idForm,
                service: i.service_name,
                institution: i.institution_name,
                Permiso: <input  id={'check'+i.id} type="checkbox" onClick={(e)=>this.permission(e,i.id)}/>
            }
            array.push(element)
            }
        }catch(e){console.log(e)}
        const column = [
        {label:'Id',field:'id',sort:'asc'},
        {label:'Id del formulario',field:'idForm',sort:'asc'},
        {label:'Servicio',field:'service',sort:'asc'},
        {label:'Institutcion',field:'institution',sort:'asc'},
        {label:'Permiso',field:'Permiso',sort:'asc'}
        ]
        const result = {columns:column, rows: array}
        this.checkPermision(roles.data.rol)
        this.setState({
        AllForm: result,
        loading: false
        })
        
}


    async permission(e,idForm){
        let user = this.state.idUser;
        let body = {
            idUser: user,
            idForm: idForm
        }
        if(e.target.checked){
            const rol = await saveRol(body);
            console.log('rol :', rol);
        }else{
            const result = await deleteRolesById(user,idForm)
            console.log('result :', result);
        }
    }




    async setRouter(e){
        let element = e.target;

        let body = {
            router: element.id,
            idUser: this.state.idUser
        }
        if(element.checked){
            const result = await saveRouter(body)
            console.log('result :', result);
        }else{
            const remove = await deleteRouter(body.idUser, body.router)
            console.log('remove :', remove);
        }
    }


    search(e){
    let value = e.target.value;
    let data = []; 
    let result  = [];

    if(this.state.AllForm){
        data = this.state.AllForm.rows
    }else{

         
        this.showDataTableForm()
        
        return
    }

    if(value.length<3 || !value || this.state.loading){
        return
    }
     result = data?data.filter(function (form,n){
        return form.idForm.toLowerCase().search(value.toLowerCase()) !== -1;
    }):[];

    

    if(result.length===0){
        result = data?data.filter(function (form,n){
            return form.institution.toLowerCase().search(value.toLowerCase()) !== -1;
        }):[];
    }
    
    if(result.length===0){
        result = data?data.filter(function (form,n){
            return form.service.toLowerCase().search(value.toLowerCase()) !== -1;
        }):[];
    }


    this.setState({
        AllForm: result.length === 0 ? data : {columns: this.state.AllForm.columns, rows:result}
    })

    if(!value){
        this.showDataTableForm()
    }
    

    console.log('value result  :', result);
    }




    render() {
        return (
        <div className="App" style={{overflowY: 'scroll', height: window.innerHeight, padding: '2%'}}>
        <ToastContainer/>
        <br/><br/>
        <h1>Users</h1>
        <h5>Ambiente: {config.env}</h5>
        
        <MDBDataTable
            striped
            bordered
            hover
            data={this.state.AllUsers}
        />
                
            {/** =================== MODAL =================== */}
            
        <MDBModal isOpen={this.state.modal} toggle={this.toggle} size="lg" >
            <MDBModalHeader toggle={this.toggle}>Asignar permisos a <b>{this.state.name}</b></MDBModalHeader>
            <MDBModalBody>
            {this.state.loading? (<MDBIcon icon="spinner" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />):''}
                {/**    
                <input type="search" className="form-control" placeholder="buscar....." onChange={(e)=>{this.search(e)}}/><br/>
                 */}
            {!this.state.loading&&(
                <div>
                <label>Buscar un formulario</label>
                    
                <MDBDataTable
                    striped
                    bordered
                    hover
                    paging={false}
                    searching={false}
                    data={this.state.AllForm}
                />
                </div>
            )}
            
            
            </MDBModalBody>
        </MDBModal>






             
            {/** =================== MODAL ROUTER =================== */}
            
            <MDBModal isOpen={this.state.modalRouter} toggle={this.toggleRouter} size="lg" >
                <MDBModalHeader toggle={this.toggleRouter}>Asignar rutas a <b>{this.state.name}</b></MDBModalHeader>
                <MDBModalBody>
                {this.state.loading? (<MDBIcon icon="spinner" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />):''}
                <MDBTable striped>
                    <MDBTableHead>
                        <tr>
                            <th>#</th>
                            <th>Ruta</th>
                            <th>Permiso</th>
                        </tr>
                    </MDBTableHead>
                    <MDBTableBody>
                        <tr>
                            <td>1</td>
                            <td>Tablero</td>
                            <td><input onClick={e =>this.setRouter(e)} type="checkbox" id="dash"/></td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Reglas</td>
                            <td><input onClick={e =>this.setRouter(e)} type="checkbox" id="rule"/></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Mascaras</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="mask"/></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Integracion Qr</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="qr"/></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Formularios</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="form"/></td>
                        </tr>
                        <tr>
                            <td>6</td>
                            <td>Transferencia</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="tranfer"/></td>
                        </tr>
                        <tr>
                            <td>7</td>
                            <td>Administrador</td>
                            <td><input  onClick={e =>this.setRouter(e)}  type="checkbox" id="admin"/></td>
                        </tr>
                    </MDBTableBody>
                    </MDBTable>
                </MDBModalBody>
            </MDBModal>



        {this.state.loading? (<MDBIcon icon="spinner" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />):''}
        </div>
        );
    }
    }

export default Users;
