import config from "../../config";
import axios from "axios";


    function axios_api(api) {
    axios.defaults.baseURL = api;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token-rules")}`; 
    return axios
    }


    function saveConfigurations(body) {
        console.log('data :', body)
        const result =  axios_api(config.api+'/configurations').post(config.api+'/configurations',body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }


    function updateConfigurations(body,id) {
        console.log('data :', body)
        const result =  axios_api(config.api+'/configurations/'+id).put(config.api+'/configurations/'+id,body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
        
    }


    function GetConfigurations(id) {
        const result =  axios_api(config.api+'/configurations/'+id).get(config.api+'/configurations/'+id).then((res)=>{        
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
    
        return result;
        
    }


    function deleteConfigurationsByForm(id) {

        const result =  axios_api(config.api+'/configurations/'+id).delete(config.api+'/configurations/'+id).then((res)=>{
        return {'data':res.data,'ok':true};
        
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        return result;
        
    }




    export {
        saveConfigurations,
        updateConfigurations,
        deleteConfigurationsByForm,
        GetConfigurations
    }