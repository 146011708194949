import React, { Component } from 'react';
import Types from "prop-types";
import './App.css';
import  Content  from "../Body/content";
import Header from "../Body/header";
import SideBar from '../Body/sideBar';
import Home from '../Home/home';
import { ToastContainer,toast} from 'react-toastify';
import IdleTimer from 'react-idle-timer'
import { firebase } from "../../firebase";
import { ErrorByFirebase } from "../../Helpers/helpers";
import { User } from "../../data/index";
import config from '../../config';
import { 
  MDBIcon,  MDBContainer,
  MDBBtn, MDBModal, MDBModalBody,MDBAnimation,MDBNavbar , MDBModalHeader,MDBNavbarNav , MDBModalFooter,  MDBRow, MDBCol } from "mdbreact";

  const { logiForToken,isAuth } = User
class App extends Component {

  constructor(props){
    super(props)
    this.idleTimer = null
    this.state = {
      isLogin: false,
      modal: false,
      loading: false,
      pass: '',
      user: '',
      hidden: false,
      animation: 'slideInLeft',
      col: '10'
    }
    this.isLogin = this.isLogin.bind(this)
    this.toggle = this.toggle.bind(this)
    this.UserInput = this.UserInput.bind(this);
    this.PassInput = this.PassInput.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.updateDimensions = this.updateDimensions.bind(this);
    this.openSide = this.openSide.bind(this)
  }
  static Types = {
    children: Types.object.isRequired
  }
  async UNSAFE_componentWillReceiveProps(next){
    if(next !== this.props){
      const result = await isAuth()

      if(result.code === 401){
        this.setState({
          isLogin: false,
          modal: true
        })
        this.logout()
      }
    }
  }
  handleKeyPress(e,action) {
    console.log(e.key)
    if(e.key === 'Enter'){
      switch(action) {
        case 'relogin':
          this.Login()
          break;
        default:
          break
      }
    }
  }
  isLogin(login){
    this.setState({
      isLogin: login
    })
  }
  
  toggle = () =>{

  }

  logout(){
console.log('object 12312:');
    localStorage.setItem('token-rules','')
    localStorage.setItem('nameUser', '')
    localStorage.setItem('idUser', '')
    localStorage.setItem('isLogin', false)
    localStorage.setItem('rol', 0)
    localStorage.setItem('userLoggedIn', false)
    
     this.setState({
       isLogin: false,
       modal: false
     })
     window.location.href = '/login'
  }

  UserInput(e){
    this.setState({
      user: e.target.value
    })
  }

  PassInput(e){
    this.setState({
      pass: e.target.value
    })
  }


  async Login(){
    this.setState({
      loading: true
    })
    const result = await logiForToken(this.state.user, this.state.pass)
    if(result.ok){
      
      localStorage.setItem('token-rules', result.data.token_rules)
      localStorage.setItem('nameUser', result.data.user.user.firstname)
      localStorage.setItem('idUser', result.data.user.user.id)
      localStorage.setItem('isLogin', true)
      localStorage.setItem('rol', 2)
      localStorage.setItem('userLoggedIn', result.data.user.user.email)
      toast.info('su sesion inicio nuevamente',{
        position: toast.POSITION.TOP_RIGHT
      })
      window.location.reload()
      
      this.setState({
        modal: false,
        isLogin: true,
      })
    
    }else{
      console.log('result.data :', result.data);
    }
    this.setState({
      loading: false,
    })
  
  }

  openSide(option){
    this.setState({
      hidden:option,
      animation: 'slideInLeft',
      col: !option?'10':'12'
  })
  }
  resize(value,height){
    console.log('value :', value);
    if(value<1120){
        this.setState({
            hidden: true,
            animation: 'slideOutRight' 
        })
    }else{
      this.setState({
        hidden:false,
        animation: 'slideInLeft',
        col: '10'
    })
    }



    if(value<1119){
      this.setState({
        col: '12'
    })
    }
}

  componentDidMount() {
      // Additionally I could have just used an arrow function for the binding `this` to the component...
      window.addEventListener("resize", this.updateDimensions);
  }
  updateDimensions() {
      this.setState({
      height: window.innerHeight, 
      width: window.innerWidth
      });
      this.resize(this.state.width, this.state.height)
  }
  componentWillUnmount() {
      
      window.removeEventListener("resize", this.updateDimensions);
  }
  render() {
    console.log('this.state.height :', this.state.height);
    const {children} = this.props
    if(window.location.pathname === '/' || window.location.pathname === '/login'){
      return(
        <div className="App">
          <Content isLogin={this.isLogin} body={children}/>
        </div>
      )
    }
    return (
      <div style={{backgroundColor: 'white', height: 2000}} >
      <MDBNavbar className=" z-depth-0" style={{position: 'relative', zIndex: 1}}>
        <MDBNavbarNav className=" z-depth-0" left>
          <MDBIcon  style={{color: '#ef6c00'}} icon="bars" onClick={()=>this.openSide(false)} />
        </MDBNavbarNav>
        <MDBNavbarNav className=" z-depth-0" right>
          
          <MDBIcon  style={{color: '#ef6c00', fontSize: 20}} icon="sign-out-alt" onClick={()=> this.logout()} />

        </MDBNavbarNav>
      </MDBNavbar>
      <MDBRow>
      
        
          <MDBCol md="2">
          
            <SideBar isLogin={this.isLogin} open={this.openSide} hidden={this.state.hidden}/>
          
            
          </MDBCol>
        <MDBCol md={this.state.col} style={{paddingLeft:50, paddingRight: 50}}>
          <Content isLogin={this.isLogin} body={children}/>
        </MDBCol>
      </MDBRow>
      
      
      </div>
    );
  }
}
export default App;
