import React, { Component } from 'react';



export default class TSelect extends Component {
      // constructor(props){
      //   super(props)
    
      
      // }
  
    componentWillReceiveProps(props){
      /*
      if(props.fieldToInput){
        for (let index = 0; index < document.getElementsByTagName("select").length; index++) {
          document.getElementsByTagName("select")[index].style = ""
          
        }
        
        document.getElementById(props.fieldToInput).style = "box-shadow: 5px 5px 10px grey;"
      }
      */
      
    }
    render() {
      const field = this.props.field
      const i = this.props.id
      return (
          <div className="form-group">
          <label htmlFor={field.label} >{field.label}</label>
          <select   key={i} id={field.name} className="browser-default custom-select" disabled>
          <option>{'id: '+field.name}</option>
            
            
          </select>
        </div>
      );
    }
  }
  
  