import React, { Component } from 'react';


class Page404 extends Component {
  render() {
    return (
      <div className="App">
       <h1>Usted no esta logueado</h1>
      </div>
    );
  }
}

export default Page404;