import * as firebase from 'firebase'

const  config = {
  apiKey: "AIzaSyAnQB8EBg5OmbeWsmVuOHqyvtQsttwmuH8",
  authDomain: "softexpert-bebc7.firebaseapp.com",
  databaseURL: "https://softexpert-bebc7.firebaseio.com",
  projectId: "softexpert-bebc7",
  storageBucket: "softexpert-bebc7.appspot.com",
  messagingSenderId: "286431619088",
  appId: "1:286431619088:web:f48ce75a3b9d6025"
};
firebase.initializeApp(config)



export  {
firebase
}