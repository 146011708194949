
import React, { Component } from 'react';
import { MDBRow, MDBCol,MDBIcon,MDBCard,MDBProgress,MDBContainer, MDBBtn, MDBModal, MDBModalBody, MDBModalHeader, MDBModalFooter  } from "mdbreact";
import { ToastContainer, toast } from 'react-toastify';
import { firebase } from "../../firebase";
import {ErrorByFirebase} from '../../Helpers/helpers';
import { Form } from "../../data/index";
import config from '../../config';
const { GetFormularyById, updateFormularyById} = Form
 class ModalRol extends Component {
    constructor(props){
        super(props)
        this.state = {
            loading: false,
            rol: '',
            pass: '',
            email:''
        }
    }

   

    async saveForm(){
  

    }
 
    async update(){
            this.setState({
                loading: true
            })
            const result = await firebase.auth()
            .signInWithEmailAndPassword(this.state.email, this.state.pass)
            .then((u)=>{
                    let user = firebase.auth().currentUser;
                    user.updateProfile({
                    photoURL: this.state.rol,
                    })
                    return {'data': user, 'ok': true}
            })
            .catch((error)=> {
                return {'data': error.code, 'ok': false}
            });

            if(result.ok){
                toast.success('Se actualizo correctamente el usuario', {
                    position: toast.POSITION.BOTTOM_CENTER
                });
                this.props.toggle()
            }else{
                toast.error('Error al actualizar el rol',ErrorByFirebase(result.data),  {
                    position: toast.POSITION.BOTTOM_CENTER
                });
            }
            this.setState({
                loading: false
            })
            
            
            
    }

    
    render() {
        return (
        <div className="App">
        <MDBContainer>
        <MDBModal isOpen={this.props.modal} toggle={this.props.toggle} size="lg" >
            <MDBModalHeader toggle={this.props.toggle}>Cambiar el rol de un usuario</MDBModalHeader>
            
                {this.state.loadingData?(
                <div>
                    <MDBIcon icon="spinner" spin size="2x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />
                    <label>cargando....</label>
                </div>
                )
                :
                (
                    <MDBModalBody>
                <MDBRow>
                
                <MDBCol>
                <br/>
                            <label htmlFor="defaultFormLoginEmailEx" >
                            Email
                            </label>
                            <input
                            type="email"
                            id="id_formulary"
                            name="id_formulary"
                            className="form-control"
                            onChange={ e => this.setState({email: e.target.value})}
                            value={ this.props.idForm }
                            />
                            <br />
                           
                </MDBCol>
                <MDBCol>
                <br/>
                <label htmlFor="defaultFormLoginEmailEx" >
                password
                </label>
                <input
                type="password"
                id="id_formulary"
                name="id_formulary"
                className="form-control"
                onChange={ e => this.setState({pass: e.target.value})}
                value={ this.props.idForm }
                />
                <br />
                           
                </MDBCol>
            </MDBRow>
            <MDBRow>
                <MDBCol>
                    <label htmlFor="defaultFormLoginEmailEx" >
                    Nivel del Rol
                    </label>
                    <select className="form-control" name="rol" onChange={(e)=>this.setState({rol: e.target.value})}>
                    <option value="0">seleccione</option>
                    <option value="0">Observador</option>
                    <option value="1">Editor</option>
                    <option value="2">Administrador</option>
                    </select>
                    
                    <br />
                </MDBCol>
            </MDBRow>
            </MDBModalBody>
                )
                }
            
            <MDBModalFooter>

                {this.state.loading&&(
                    <MDBIcon icon="spinner" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />
                )}
        
            <MDBBtn color="default"  onClick={()=> this.update()}>Guardar</MDBBtn>
            </MDBModalFooter>
        </MDBModal>
        </MDBContainer>
        </div>
        );
    }
}

export default ModalRol;






