import React, { Component } from 'react';
import {MDBIcon} from "mdbreact";
import {  toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Resumen.css'
class ResumenCard extends Component {

  constructor(props){
    super(props)
    this.state = {
      arrayRules: [],
      rule: '',
      propsOld: this.props.fieldToInput,
      OneRender: true
    }

    
  };


  componentWillReceiveProps(props){
    
    if(!props.dontRender){
      if(this.props !== props){

      this.state.OneRender&& this.dataOfRule(props.data)
      let fields = [];
      if(props.fieldFromInput && props.noRules && props.fieldToInput ){
        if(props.fieldToInput.indexOf(',') > -1){
          let ToFields = props.fieldToInput.split(',');
          
          for (let i = 0; i < ToFields.length; i++) {
          
              fields.push(props.fieldFromInput + '='+ props.noRules +':'+ ToFields[i] )
      
          }
        
        }else{
            fields = props.fieldFromInput + '='+ props.noRules +':'+ props.fieldToInput
          //lalo
        }
            this.isArray(fields,props)
          if(props.ruleSelected !==null){
            this.delete(props.fieldFromInput + '='+ props.ruleSelected+':'+ props.fieldToInput)
          }
      }
      }
  }
  }
  isArray(fields,props){
    if(Array.isArray(fields)){
      for (const i in fields) {
        if(this.filterRule(fields[i])){
          let rule =  fields[i].split('=')[1];
          let rules = rule.split(':');
          if(this.blockRule(rules[0],rules[1])){
            this.setState({ arrayRules: this.state.arrayRules.concat( fields) })
          }
        }
      }
    }else{
        if(this.filterRule(fields)){
          if(this.blockRule(props.noRules,props.fieldToInput )){
            this.setState({ arrayRules: this.state.arrayRules.concat( fields) })
          }
        }
    }
  }
  filterRule(rule){
   for (let i = 0; i < this.state.arrayRules.length; i++) {
     const element = this.state.arrayRules[i];
     if(element){
      if(element.indexOf(rule) > -1){
        toast.warn(' ya existe la regla ['+this.props.noRules+'] con este campo: ['+this.props.fieldToInput+'] ', {
          position: toast.POSITION.BOTTOM_LEFT
        }); 
        return false
       }
       
     }
     }
    
   return true
  }
  blockRule(noRule,field){
    //console.log('RULE AND FILES :', noRule , field);
    switch (noRule.toString()) {
      case '0':
      return this.findRule('1',field)
      case '1':
      return this.findRule('0',field)
      case '2':
      return this.findRule('3',field)
      case '3':
      return this.findRule('2',field)
      case '4':
      return this.findRule('6',field)
      case '6':
      return this.findRule('4',field)
      default:
      return true
        // break;
    }
  }
  findRule(noRule,field){
    
    for (let i = 0; i < this.state.arrayRules.length; i++) {
        if(this.state.arrayRules[i]){
        const element = this.state.arrayRules[i];
        if(element.indexOf(this.props.fieldFromInput+'='+noRule+':'+field) > -1){
          toast.warn('En este campo ['+field+'] existe la regla ['+noRule+'], no se puede aplicar la regla deseada', {
            position: toast.POSITION.BOTTOM_LEFT
          });
          return false
        }
        
      }
    }
    return true
  }

  delete(field){

      if(field.includes("I|") || field.includes("O")){
        let element = field.split("=");
        let e = document.getElementById(element[0]);
        e.style="color: black"
        e.setAttribute('data-selected', 'false')
      }
    let array = this.state.arrayRules.filter(function (el) {
                  return el !== field;
                });

    this.setState({
      arrayRules: array
    })
  }
  dataOfRule(data){
    let arrayFinal = [];
    try{
      if(typeof data !== 'undefined'){
        if(data[0]){
          let array = data[0].rule.split('&');
          for (let i = 0; i < array.length; i++) {
              const element = array[i].split('=')
                let fieldFrom = element[0];
                let dataFull = element[1].split(':') 
                let rules = dataFull[0].split(',')
                let fields = dataFull[1].split(',')
              for (let x = 0; x < rules.length; x++) {
                const dataRule = fieldFrom + '='+rules[x] +':'+fields[x]
                arrayFinal.push(dataRule);
              }
          }
          array.length>0&& this.setState({arrayRules: arrayFinal, OneRender: false})
        }
      } 
    }catch(e){}
  }
  render() {
    return (
      <div >
        <div id="Resumen" className="scrollingDiv padding-resumen z-depth-0"  >
        {this.state.arrayRules.length >0  && 
          this.state.arrayRules.map((rule,i) =>
          [<div id={i}>
            {this.changeRule(rule)} 
            <MDBIcon icon="times-circle" size="lg" className="text-danger cursor" onClick={e =>this.removeElement(i, rule)}/>
            </div>
          ,<hr id={i + 'line'}/>]
        )
        }    
      </div>
      </div>
    );
  }
  changeRule(rule){
    let field = rule.split("=");
    let rulesFull = field[1].split(":");
    let fieldFrom = field[0];
    return fieldFrom +' = '+rulesFull[0]+' : ' + rulesFull[1] + '; '
  }
  removeElement(elementId, rule) {
    this.delete(rule)
  }
}
export default ResumenCard;