import React, { Component } from 'react';
import 'react-toastify/dist/ReactToastify.css';
export class ResumenCardMask extends Component {
    // constructor(props){
    //     super(props)
    //   }
      render() {
        const field = this.props.arrayMask;
        // const i = this.props.id
            return (
            <div className="form-group">
                <label htmlFor="resumen">
                Máscaras Añadidas
                </label>
                <textarea
                readOnly
                className="form-control"
                id="resumenArea"
                rows="5"
                value={field}
                />
            </div>
            )
        }
    }
