import React, { Component } from 'react';
import { MDBCarousel, 
         MDBCarouselCaption, 
         MDBCarouselInner, 
         MDBCarouselItem, 
         MDBView, MDBRow, MDBCol,MDBCard, MDBIcon} from "mdbreact";
import config from '../../config';
import {  Form, } from "../../data/index";
import Chart from './chart';
import ChartGrafic from './chartGrafic';
import ChartsForm from './chartForm';

const { GetAllFormulary, isAuth} = Form

class Home extends Component {
  constructor(props){
    super(props);

    this.state = {
      bk: '',
      se: '',
      inst: 0,
      servicesTotal: 0,
      top5Services: [],
      lastServices: [],
      noData: false,
      graficBig: true
    }
  }



  async componentDidMount(){
   
    
    let  services = 0
    let institutions = 0;
    let formSE = 0;
    let formBK = 0
    
    const result = await GetAllFormulary();
    console.log('result.data.Formulary :', result.data.Formulary);
    if(result.data.Formulary){
      this.filterdata(result.data.Formulary)
      services = result.data.Formulary.length;
    }else{
      this.setState({
        noData: true
      })
    }

    console.log('services :', services);
    console.log('object :', window.innerHeight);
    if(window.innerHeight<700){
      this.setState({
      graficBig: false
      })
    }

    if(window.innerHeight>700){
      this.setState({
      graficBig: true
      })
    }
  }

  filterdata(data){

    this.setState({
      lastServices: data.slice(Math.max(data.length - 4, 1)),
      servicesTotal: data.length,
      bk:  this.filterForm(data)[0].length,
      se:  this.filterForm(data)[1].length,
      top5Services:  this.mostServicesForInst(data),
      inst:  this.filterInstitution(data).length
    })

    
  }

  filterInstitution(data){
    let result = []
    for (const key in data) {
      result.push(data[key].idinstitution)
    }
    return result.filter(function(item, index){
      return result.indexOf(item) >= index;
    });
  }

  filterForm(data){
    let se = []
    let bk = []
    for (const key in data) {
      if(data[key].provider==='bk'){
        bk.push(data[key])
      }else{
        se.push(data[key])
      }
      
    }
    return [bk,se]
  }


  mostServicesForInst(data){
    let inst = [];
    let allInst = []
    let labels = []
    let dataLenght = []

    for (const key in data) {
        inst[data[key].idinstitution] = []
    }

    for (const key in inst) {
      let result = inst[key]
      for (const i in data) {
        if (data[i].idinstitution == key) {
          
            result.push(data[i])
          
        }
      }
      
      allInst.push(result)
    }
   
   
    let arraySort = allInst.sort(function(a, b){  return a.length-b.length});
    let last5 =  arraySort.slice(Math.max(arraySort.length - 5, 1));

    for (const key in last5) {
      for (const i in last5[key]) {
        labels.push(last5[key][i].institution_name.split('-')[0])
      }
      dataLenght.push(last5[key].length)
    }
    labels = labels.filter(function(item, index){
      return labels.indexOf(item) >= index;
    });

    let result = [labels,dataLenght];

      return result

  }



  render() {
    console.log('env :', this.state.graficBig);

    if(this.state.noData){
      return(
        <div>
            <h1>Usted no esta logueado</h1>
        </div>
      )
    }
    return (
      <div className="bg" style={{overflowY: 'scroll', height: this.state.graficBig?800: 700, padding:'1%'}} >
      <br/>
      <MDBRow >
        <MDBCol>
        <MDBCard className="padding" >
          <ChartGrafic graficBig={this.state.graficBig}/>
        </MDBCard>
        </MDBCol>
      </MDBRow>
      <br/>
      <MDBRow>
      <MDBCol sm="6">
          
      <MDBCard className="padding" >
        <ChartsForm  se={this.state.se || 1} bk={this.state.bk || 1}/>
      </MDBCard>
      </MDBCol>

      {/* <MDBCol sm="6">
      <MDBCard >
      {this.state.lastServices.length<=0&&(
          <MDBIcon icon="spinner" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}} />

        )
      }
          <Chart  services={this.state.top5Services}/>
      </MDBCard>
      </MDBCol> */}
      
  </MDBRow>
  <br/>
  <hr/>
  <br/><br/>
 
  

  
  
    </div>
    );
  }
}

export default Home;