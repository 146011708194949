import React, { Component } from 'react';
import {MDBCard,MDBCol} from "mdbreact";
import config from '../../config';
import './rulesBox.css'
class ruleBox extends Component {
  constructor(props){
    super(props)
    this.state = {
      selected: true,
      class: 'padding ',
      rule: this.props.rule,
      Resumen:  [] 
    }
  }

  componentWillReceiveProps(props){
    
    if(!props.noRules){
      this.setState({
        class: 'padding '
      })
    }
    
  }
  render() {
    const name = this.props.name
    
    return (
      <div >
        <MDBCol className="padding-col"  >
          <MDBCard className={this.state.class }  onClick ={ (e)=> this.click(e)} >
            {name}
          </MDBCard>
        </MDBCol>
      </div>
    );
  }

  click(){
    let valueClass = this.state.class;
    if(this.state.selected ){
      this.setState({
        class: valueClass + ' default-color text-white',
        selected: !this.state.selected
      })
      this.props.handlerRule(this.props.rule)
      this.props.RuleSeleted(null)
    }else{
      this.setState({
        class: 'padding',
        selected: !this.state.selected
      })
      this.props.RuleSeleted(this.props.rule)
    }
   
  }


 
}

export default ruleBox;