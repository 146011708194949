//=================================== CHANGE ENVIRONMENT ==================================
    const option = 'local'
//=========================================================================================

function env(env) {
    switch (env) {
        case 'local':
            return ['https://dev.apireglas.servicios.mitur.gob.do/rules/api',env,'grey darken-1','http://des-reglas.mitur.gob.do/']
        default:
            return ''
    }
}

try {
    module.exports ={
        api:    env(option)[0],
        env:    env(option)[1],
        color:  env(option)[2],
        qrApi: env(option)[3],
        SECRET_TOKEN: 'palolo'
    }
} catch (e) {}
