import React, { Component } from 'react';
import {MDBCol,MDBIcon} from "mdbreact";
import {  Redirect } from 'react-router-dom';
import config from '../../config';
class BtnNewRule extends Component {

    constructor(props){
        super(props)
        this.state = {
            shadow: '',
            newRule: false
        }
    }

    toggle(){
      this.setState({
        newRule: true
      })
    }
  render() {

    console.log('this.props :', this.props.idForm);
    if(this.state.newRule){
      return(
        <Redirect to={{
                      pathname: '/render',
                      state: {data: this.props.data, idForm: this.props.idForm }
                     }} 
        />
      )
    }

    return (
      <div className="App padding">
      
                <MDBCol   onClick={()=>this.toggle()} onMouseOut={()=> this.setState({shadow: ''})} onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})} size="12" className={"border radius padding " +this.state.shadow }>
                    <MDBIcon  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})} icon="plus-circle" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}} size="2x" className=" cursor"></MDBIcon>
                    <h5  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})}>Nueva Regla</h5>
                </MDBCol>
       
      </div>
    );
  }
}

export default BtnNewRule;