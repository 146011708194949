import React, { Component } from 'react';
import { MDBRow, MDBCol} from "mdbreact";
import  CardText  from "../Utilities/CardText";
import './RenderField.css'
import  FieldsLeft  from "./FieldsLeft";
import  FieldsRight  from "./FieldsRight";
import { ToastContainer, toast } from 'react-toastify';
class RenderField extends Component {
    constructor(props){
        super(props)
        this.state = {
            fieldFrom:[],
            field: []
        }
    }
    componentDidMount(){
        let dataFieldFrom = []
        let dataField = []
        if(this.props.data.length>0){
            for (const key in this.props.data) {
                dataField.push(this.props.data[key][0])
                dataFieldFrom.push(this.props.data[key][1])
            }
           this.setState({ field: dataField, fieldFrom: dataFieldFrom  })
        }
      
    }
    hiddenPage(id){
        let element = document.getElementById(id)

        if(element.style.display ==='none'){
            element.style='display:block'
        }else{
            element.style='display:none'
        } 
    }
  render() {
      
    return (
      <MDBRow className="App">
      <ToastContainer/>
        <MDBCol className="scrollingDivField">
            <CardText color="rgb(201, 201, 201)" text="CAMPOS QUE ACCIONAN REGLAS"/>

            <br/>
            {this.state.fieldFrom.map((fields,i)=>(
                <MDBRow key={i}>
                    <MDBCol  >
                    <div className="cursor" onClick={()=> this.hiddenPage("FromPage"+i)}>
                    <CardText  color="#00695c" text={"Pagina: "+ i}/>
                    </div>
                    <div id={"FromPage"+i}>
                        <FieldsLeft key={i} field={fields} FromInput={this.props.FromInput} FieldFromInput={this.props.FieldFromInput} />
                    </div>
                    </MDBCol>
                    <hr/>
                </MDBRow>
            ))}
        </MDBCol>
        <MDBCol className="border-render scrollingDivField" style={{display: this.props.FieldFromInput? '' : 'none'}}>
            
            <CardText color="rgb(201, 201, 201)" text="APLICAR REGLA A"/>
            <br/>
            {this.state.field.map((fields,i)=>(
                
                <MDBRow >
                    
                    <MDBCol  >
                    <div className="cursor" onClick={()=> this.hiddenPage("ToPage"+i)}>
                    <CardText  color="#00695c" text={"Pagina: "+ i}/>
                    </div>
                    <div id={"ToPage"+i}>
                        <FieldsRight ToInputByAllSelected={this.props.ToInputByAllSelected} field={fields} FieldToInput={this.props.FieldToInput} ToInput={this.props.ToInput} />
                    </div>
                    </MDBCol>
                    <hr/>
                </MDBRow>
            ))}
        </MDBCol>
      </MDBRow>
    );
  }
}

export default RenderField;