import React, { Component } from 'react';
import {MDBCol,MDBIcon,} from "mdbreact";
import config from '../../config';
class BtnBack extends Component {
    constructor(props){
        super(props)
        this.state = {
            shadow: '',
            newRule: false
        }
    }

   
  render() {



    return (
      <div className="App padding">
        
                <MDBCol onClick={()=>window.location.href = "/rules"} onMouseOut={()=> this.setState({shadow: ''})} onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})} size="12" className={"border radius padding " +this.state.shadow }>
                    <MDBIcon  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})} icon="arrow-left" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}} size="2x" className=" cursor"></MDBIcon>
                    <h5  onMouseEnter={()=> this.setState({shadow: 'z-depth-1'})}>Volver</h5>
                </MDBCol>
        
      </div>
    );
  }
}

export default BtnBack;