import config from "../../config";
import axios from "axios";


    function axios_api(api) {
    axios.defaults.baseURL = api;
    axios.defaults.headers.post['Content-Type'] ='application/json;charset=utf-8';
    axios.defaults.headers.post['Access-Control-Allow-Origin'] = '*';
    axios.defaults.headers.common['Authorization'] = `Bearer ${localStorage.getItem("token-rules")}`; 
    return axios
    }
    function saveMask(data,idForm,maskVersion,formatedFieldsMasked,fieldsLength,fieldsGridMasked) {
        let body = { fieldsMasked: data, 
                     idForm: idForm,
                     maskVersion:maskVersion,
                     formatedFieldsMasked:formatedFieldsMasked,
                     fieldLength:fieldsLength,
                     fieldsGridMasked:fieldsGridMasked
                    }
        console.log('result all in save Mask:',body);
        const result =  axios_api(config.api+'/saveMask').post(config.api+'/saveMask',body).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
    }
    function GetLatestMaskVersion(idForm) {
        const result =  axios_api(config.api+'/GetLatestMaskVersion/' + idForm).get(config.api+'/GetLatestMaskVersion/' + idForm).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
    }

    function GetMaskVersionById(id) {
        const result =  axios_api(config.api+'/GetMaskVersionById/' + id).get(config.api+'/GetMaskVersionById/' + id).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
    }

    function GetAllMaskVersionsPerForm(idForm) {
        const result =  axios_api(config.api+'/GetAllMaskVersionsPerForm/'+ idForm).get(config.api+'/GetAllMaskVersionsPerForm/'+ idForm).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
    }

    async function GetOneMaskVersionsPerForm(idForm) {
        const result = await axios_api(config.api+'/GetMaskVersionByIdForm/'+ idForm).get(config.api+'/GetMaskVersionByIdForm/'+ idForm).then((res)=>{
        return {'data':res.data,'ok':true};
        }).catch((error)=>{
        return {'data':error,'ok':false}
        });
        return result;
    }



    function deleteMaskByForm(id) {
        const result =  axios_api(config.api+'/mask/'+id).delete(config.api+'/mask/'+id).then((res)=>{
        return {'data':res.data,'ok':true};
        
        }).catch((error)=>{
        return {'data':error,'ok':false};
        });
        return result;
        
    }


    export {
        deleteMaskByForm,
        GetAllMaskVersionsPerForm,
        GetMaskVersionById,
        GetLatestMaskVersion,
        GetOneMaskVersionsPerForm,
        saveMask
    }