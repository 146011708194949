import React, { Component } from 'react';
import {MDBRow,MDBCol} from "mdbreact";
import { SelectableGroup, createSelectable } from 'react-selectable';
import { allFieldSelected,DelteSelected } from "../../Helpers/helpers";
import { TFile,
    THeaders,
    TRadio,
    TSelect,
    TSubTitle,
    TText,
    TTextarea,
    TTime,
    TDate,
    TGrid,
    TCheckbox } from "../form/index";
const Text = createSelectable(TText);
const File = createSelectable(TFile);
const Headers = createSelectable(THeaders);
const Radio = createSelectable(TRadio);
const Select = createSelectable(TSelect);
const SubTitle = createSelectable(TSubTitle);
const Textarea = createSelectable(TTextarea);
const Time = createSelectable(TTime);
const Dates = createSelectable(TDate);
const Grid = createSelectable(TGrid);
const Checkbox = createSelectable(TCheckbox);
class FieldsRight extends Component {
  constructor(props){
    super(props)
    this.state = {
      selected: true,
      class: 'padding ',
      rule: this.props.rule,
      Resumen:  [] ,
      selectedKeys: []
    }
    this.handleSelection = this.handleSelection.bind(this)
  }
     handleSelection (selectedKeys) {
        if(selectedKeys.length>0){
            let fields = selectedKeys.toString()
            DelteSelected()
            document.getElementById('ToInput').value = fields
            allFieldSelected(fields)
            this.props.ToInputByAllSelected(fields)
        }
    }
  render() {
    const ToField = this.props.field
    return (
        <SelectableGroup tolerance={30}  onSelection={this.handleSelection}>
        <MDBRow >
        {ToField.map((field, i)=>(
            
            field.type === 'text' ? (
                    <MDBCol size="6" className="cursor"  onClick={(e) => this.props.ToInput(e,field.name)}>
                    <Text  selectableKey={field.name} key={i} id={i + field.label} fieldToInput={this.props.FieldToInput} field={field}/>
                    </MDBCol>
            )
            :
            field.type === 'radio-group' ? (
                <MDBCol size="6" className="cursor">
                <Radio key={i} selectableKey={field.name}  fieldToInput={this.props.FieldToInput}  handlerToInput={ this.props.ToInput} id={i + field.label} field={field}/>
                </MDBCol>
            )
            :
            field.type === 'select'   ? (
                <MDBCol size="6" className="cursor" onClick={(e) => this.props.ToInput(e,field.name)}>
                <Select key={i} selectableKey={field.name}  id={i + field.label} field={field}/>
                </MDBCol>
            )
            :
            field.type === 'file' ? (
                <MDBCol size="6" className="cursor" onClick={(e) => this.props.ToInput(e,field.name)}>
                <File selectableKey={field.name}  key={i} id={i + field.label} field={field}/>
                </MDBCol>
            )
            :
            field.type === 'header' ? (
                
                <MDBCol size="12" className="cursor" onClick={(e) => this.props.ToInput(e,field.name)}>
                <hr/>
                <Headers selectableKey={field.name}  key={i} id={i + field.label}  handlerToInput={ this.props.ToInput} field={field}/>
                </MDBCol>
            )
            :
            field.type === 'textarea' ? (
                <MDBCol size="6" className="cursor"  onClick={(e) => this.props.ToInput(e,field.name)}>
                    <Textarea selectableKey={field.name}  key={i} id={i + field.label} fieldToInput={this.props.FieldToInput} field={field}/>
                </MDBCol>
            )
            :
            field.type === 'time' ? (
                <MDBCol size="6" className="cursor"  onClick={(e) => this.props.ToInput(e,field.name)}>
                    <Time selectableKey={field.name}  key={i} id={i + field.label} fieldToInput={this.props.FieldToInput} field={field}/>
                </MDBCol>
            )
            :
            field.type === 'date' ? (
                <MDBCol size="6" className="cursor"  onClick={(e) => this.props.ToInput(e,field.name)}>
                    <Dates selectableKey={field.name}  key={i} id={i + field.label} fieldToInput={this.props.FieldToInput} field={field}/>
                </MDBCol>
            )
            :
            field.type === 'grid' ? (
                <MDBCol size="12" className="cursor"  >
                    <Grid selectableKey={field.name}  key={i} id={field.name} ToInput={this.props.ToInput} fieldToInput={this.props.FieldToInput} field={field}/>
                </MDBCol>
            )
            :
            field.type === 'subtitle' ? (
                <MDBCol size="12" className="cursor" onClick={(e) => this.props.ToInput(e,field.name)}>
                <SubTitle selectableKey={field.name}  key={i} id={i + field.label} field={field}/>
                </MDBCol>
            )
            :
            field.type === 'checkbox-group' && (
                <MDBCol size="6" className="cursor" >
                <Checkbox selectableKey={field.name}  key={i} id={i + field.label} fieldToInput={this.props.FieldToInput}  handlerToInput={ this.props.ToInput}  field={field}/>
                </MDBCol>
                )
            ))}
    </MDBRow>
    </SelectableGroup>
    );
  }
}
export default FieldsRight;