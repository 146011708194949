import React, { Component } from 'react';
import { MDBCol,MDBCard,MDBCardBody,MDBBtn,MDBRow,MDBContainer,MDBIcon} from "mdbreact";
import {Softexpert} from "../../data/index";
import Select from 'react-select';
import './css/index.scss';
import { toast } from 'react-toastify';
import {env,color} from'../../config'
import config from '../../config';
const {getDocuments,getDocumentsToSend} = Softexpert
export class QR extends Component {
    constructor(props){
        super(props)
        this.state = {
            options:[],
            isDisabled:'',
            disabled:true,
            idDocument:'',
            emptyInput:'',
            buttonDisabled:true
        };
        this.disabledButton = this.disabledButton.bind(this);
        this.sendParams = this.sendParams.bind(this);
        this.verifyInput = this.verifyInput.bind(this)
    }
    verifyInput(e){

        console.log(e, document.getElementById('requestID').value)
        if(document.getElementById('requestID').value !== ""){
            this.setState({
                buttonDisabled:false
            })
        } else{
            this.setState({
                buttonDisabled:true
            })
        }
    }
    async searchID(){
        this.setState({
            isDisabled:true
        })
        this.state.options = []
        let requestID = ''
        requestID = await document.getElementById("requestID").value
        const data = await getDocuments(requestID)
        console.log('el nuevo esquema de la data', data);
        if(data.ok){
            for(const i in data.data){
                for(const x in data.data[i]){
                    console.log(data.data[i][x].iddocument, data.data[i][x].name)
                    if(data.data[i][x].iddocument!== undefined){
                        this.state.options.push({value:data.data[i][x].iddocument , 
                        label:data.data[i][x].name +' - ('+data.data[i][x].iddocument +')'})
                        this.setState({
                            isDisabled:false
                        })
                    } 
                }
                if(data.data[i].length ==31){
                    this.setState({
                        isDisabled: data.data[i] + ' asociados a este numero de solicitud'
                    })
                }
                else if(data.data.message ==""){
                    this.setState({
                        isDisabled: 'Error de conexión'
                    })
                }
            }
        }
        console.log(this.state.isDisabled,'setted2')
    }
    disabledButton(e){
        console.log(e.value,'checking')
        this.setState({
            idDocument:e.value
        })
        this.setState({
            disabled:false
        })
    }
    sendParams(){
        let idDocument = "", requestID = "";
        requestID = document.getElementById("requestID").value
        idDocument = this.state.idDocument
        if(env === 'local' || env === 'development'){
            let url="http://des-apprr.serviciosrd.gob.do/?doc="+idDocument+'&&solicitud='+requestID
            toast.success('Solicitud No. '+requestID+'\n'+' Te estamos redireccionando, por favor espere',{
                position: toast.POSITION.BOTTOM_CENTER
            })
            window.location.href = url
        }
        else if( env==='produccion'){
        toast.success('Aqui va el link de producción',{
            position: toast.POSITION.BOTTOM_CENTER
        })
            // window.location.href = url
        }
    }
    render() {
        return(
            <MDBContainer class="center-col">
                <MDBRow >
                    <MDBCol md="12">
                        <MDBCard>
                        <MDBCardBody>
                            <form>
                            <p className="h4 text-center py-1">Integración QR en Documentos</p>
                                    <label
                                        htmlFor="defaultFormCardNameEx"
                                        className="grey-text font-weight-light">
                                        Ingrese el No. de la solicitud
                                    </label>
                                <MDBRow className="d-flex align-items-center" >
                                    <MDBCol className="d-flex align-items-center">
                                        <input
                                            type="text"
                                            id="requestID"
                                            className="form-control"
                                            onChange={e => this.verifyInput(e)}
                                        />
                                    </MDBCol>
                                        <MDBBtn disabled={this.state.buttonDisabled} className="btn  btn-md text-white" color={config.color} onClick={()=>this.searchID()}>
                                            Verificar
                                        </MDBBtn>
                                </MDBRow>
                                <br />
                                {this.state.isDisabled===false?(
                                    <div>
                                        <label
                                            htmlFor="defaultFormCardEmailEx"
                                            className="grey-text font-weight-light">
                                            Seleccione el Documento a integrar código QR
                                        </label>
                                        <Select isDisabled={this.state.isDisabled} options={this.state.options} 
                                        onChange={(e)=>this.disabledButton(e)}/>
                                        <MDBBtn disabled={this.state.disabled} onClick={()=>this.sendParams()} className="btn btn-default">
                                            Enviar
                                        </MDBBtn>
                                    </div>
                                ):this.state.isDisabled===true?
                                (<MDBIcon icon="spinner" spin size="4x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed />)
                                :!this.state.isDisabled?(<b>{this.state.isDisabled}</b>):<b>{this.state.isDisabled}</b>}
                            </form>
                            <br/>
                        </MDBCardBody>
                        </MDBCard>
                    </MDBCol>
                </MDBRow>
            </MDBContainer>
        );
    } 
}
export default QR;