import React, { Component } from 'react';
export class MaskSelectOption extends Component {
    constructor(props){
        super(props)
    }
    render() {
      return (
          <div className="form-group" >
            <select className="form-control mt-3"  onChange={this.props.mask} value={this.props.value}>
                <option value="-1"  disabled>Seleccione una opción</option>
                <option value="0"> 0 - CEDULA</option><option value="1"> 1 - RNC</option>
                <option value="2"> 2 - TELÉFONO</option><option value="3"> 3 - CELULAR</option><option value="4"> 4 - Máscara 4</option><option value="5"> 5 - EMAIL</option>
                <option value="6"> 6 - SOLO NUMERO</option><option value="7"> 7- SOLO LETRA</option><option value="8"> 8 - Máscara 8</option>
                <option value="9"> 9 - EMAIL DIST. SOLICITANTE</option><option value="10"> 10 - FECHA</option><option value="11">11 - HORA</option>
                <option value="12">12 - DECIMAL</option><option value="13">13 - Máscara 13</option><option value="14">14 - Máscara 14</option>
                <option value="15">15 - Máscara 15</option><option value="16"> 16 - Máscara 16</option><option value="17">17 - LATITUD</option>
                <option value="18">18 - LONGITUD</option><option value="19">19 - MAYUSCULAS</option><option value="20">20 - Código DPP</option>
                <option value="21">21 - Código MIMARENA</option><option value="22">22 - FECHA NO MAYOR ACTUAL</option><option value="23">23 - FECHA NO MENOR ACTUAL</option>
            </select>
        </div>
      );
    }
  }
