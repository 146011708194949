import React, { Component } from "react";
import { MDBContainer,MDBNavLink, MDBIcon,MDBAnimation, MDBBtn, MDBModal,MDBListGroupItem,MDBListGroup , MDBModalBody, MDBModalHeader, MDBModalFooter } from 'mdbreact';
import './style.css';
import { firebase } from "../../firebase";
import { ErrorByFirebase } from "../../Helpers/helpers";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import config from '../../config';
import Background from '../../img/building.jpg';
import { User, Router } from "../../data/index";
const { logiForToken,isAuth } = User
const {getRouterByUser} = Router

class Header extends Component {
constructor(props){
  super(props)
  this.state = {
    isOpen: false,
    user: '',
    pass: '',
    loading: false,
    modal: false,
    modalRegister: false,
    isLogin: false,
    nameUser: '',
    activeItem: "1",
    modalForget: false,
    userLoggedIn:'',
    router: []

  };
  this.nameUser = this.nameUser.bind(this)
  this.UserInput = this.UserInput.bind(this);
  this.PassInput = this.PassInput.bind(this);
  this.modalForget = this.modalForget.bind(this);
  this.handleKeyPress = this.handleKeyPress.bind(this);
}
toggleCollapse = () => {
  this.setState({ isOpen: !this.state.isOpen });
}
  handleKeyPress(e,action) {
    console.log(e.key)
    this.setState({currentKey: e.keyCode});
    if(e.key === 'Enter'){
      switch(action) {
        case 'login':
          this.Login()
          break;
        case 'register':
          this.CreateUser()
          break;
        case 'forget':
          this.forget()
          break;
        default:
          break
      }
    }
  }
  toggle = () => {
 this.setState({
    modal: !this.state.modal
  });
}
toggleTab = tab => () =>{
  if (this.state.activeItem !== tab) {
    this.setState({
      activeItem: tab
    })
  }
}
modalForget = () =>{
  this.setState({
    modalForget: !this.state.modalForget
  });
}
toggleRegister = () => {
  this.setState({
    modalRegister: !this.state.modalRegister
  });
}
UserInput(e){
  this.setState({
    user: e.target.value
  })
}
PassInput(e){
  console.log(e)
  this.setState({
    pass: e.target.value
  })
}
nameUser(e){
  this.setState({
    nameUser: e.target.value
  })
}

async forget(){
  
  let auth = firebase.auth()
  await auth.sendPasswordResetEmail(this.state.user).then( async () => {
    toast.success('Emviamos un correo a la bandeja de entrada de  '+this.state.user,{
      position: toast.POSITION.BOTTOM_CENTER
    })
    this.setState({
      modalForget: false
    })
  }).catch( async (error) => {
    toast.error('Erro al enviar el correo',{
      position: toast.POSITION.BOTTOM_CENTER
    })
  });
 
}
async CreateUser(){
  this.setState({
    loading: true
  })
  const result = await firebase.auth()
  .createUserWithEmailAndPassword(this.state.user, this.state.pass)
  .then((u)=>{
      var user = firebase.auth().currentUser;
      console.log('this.state.nameUser :', this.state.nameUser);
      user.updateProfile({
        photoURL: 1,
        displayName: this.state.nameUser
      }).catch((error) => {
        
      });
      user.sendEmailVerification().then(function() {
        // Email sent.
      })
      return  {'data': 'Usaurio registrado exitosamente, revise la bandeja de entrada de '+this.state.user, 'ok': true}
  })
  .catch((error)=> {
    return {'data': error.code, 'ok': false}
  });

  if(result.ok){
    toast.success(result.data,{
      position: toast.POSITION.BOTTOM_CENTER
    })
    this.setState({
      loading: false,
      modalRegister: false
    })
  }else{
    toast.error(ErrorByFirebase(result.data),{
      position: toast.POSITION.BOTTOM_CENTER
    })
  }
  this.setState({
    loading: false,
  })

}

async Login(){
  this.setState({
    loading: true
  })
  

  
    const result = await logiForToken(this.state.user, this.state.pass)
    console.log('token :', result);

    if(result.ok){
      localStorage.setItem('token-rules', result.data.token_rules)
      localStorage.setItem('nameUser', result.data.user.user.firstname + ' ' + result.data.user.user.lastname)
      localStorage.setItem('idUser', result.data.user.user.id)
      localStorage.setItem('isLogin', true)
      localStorage.setItem('rol', 2)
      localStorage.setItem('userLoggedIn', result.data.user.user.email)
  
      this.setState({
        modal: false,
        isLogin:  result.data.user.auth,
        nameUser: result.data.fistname,
        rol: 2,
        userLoggedIn:result.data.email
      })
      window.location.href = window.location.href
    }else{
      this.setState({
        loading: false,
      })
      toast.error('Este usuario no esta registrado en el portal administrativo',{
        position: toast.POSITION.BOTTOM_CENTER
      })
    }


}
logout(){
    this.setState({
      isLogin: false,
      nameUser:'',
      redirect: true
    })
    localStorage.setItem('token-rules','')
      localStorage.setItem('nameUser', '')
      localStorage.setItem('idUser', '')
      localStorage.setItem('isLogin', false)
      localStorage.setItem('rol', 0)
      localStorage.setItem('userLoggedIn', false)
}
async componentDidMount(){
    this.setState({
      isLogin:  localStorage.getItem('isLogin'),
      nameUser: localStorage.getItem('nameUser'),
      rol: localStorage.getItem('rol'),
      emailVerified: true
    })
    this.props.isLogin(true)
    let routers = []
    const result = await getRouterByUser(localStorage.getItem('idUser'))
    if(result.data.routers){
      result.data.routers.forEach(element => {
        routers[element.router] = true
        
      });
      this.setState({
        router: routers
      })
      console.log('object :938274479472398',this.state.router);
    }
    
 

  //document.addEventListener('keydown', this.handleKeyPress);
}

render() {
  console.log('this.state :', this.state);
  return (
    <div className={this.props.hidden? 'hidden': 'visible'} style={{backgroundImage: `url(${Background})`, WebkitBackgroundSize: 'cover', MozBackgroundSize:'cover', backgroundSize:'cover', OBackgroundSize: 'cover', height: 1000, position: 'absolute', top: -40, zIndex: 2}}  >
    <div  className={this.props.hidden? 'hidden': 'visible'} style={{backgroundColor: 'rgb(240, 119, 6,0.8)', height: 1000}} >
        <center>
        <MDBIcon  icon="times-circle" style={{color: 'white', position: 'absolute', top: 15, right: '220px', left: 10}} onClick={()=> this.props.open(true)} />
        <br/>
        <a href="/home"><img  src={require('../../img/icon_logo.png')} width="200"/></a>
        <br/><br/>
        <label style={{color: 'white'}}>Administración de Formularios</label>
        <br/>
        </center>
        <hr style={{width: '100%', backgroundColor: 'white'}}/>
        <ul style={{ width: '100%', backgroundColor: 'rgb(240, 119, 6,0.0)'}} between>
            <li style={{ width: '100%', backgroundColor: 'rgb(240, 119, 6,0.0)' ,border: 0, color: 'white', paddingTop: '10%'}} ><MDBNavLink style={{color: 'white'}}  to="/Mask"><MDBIcon  icon="dollar-sign" />&nbsp;&nbsp;<b >Mascaras</b></MDBNavLink></li>
            <li style={{ width: '100%', backgroundColor: 'rgb(240, 119, 6,0.0)' ,border: 0, color: 'white',paddingTop: '20%'}} ><MDBNavLink style={{color: 'white'}}  to="/optionsRules"><MDBIcon  icon="align-left" />&nbsp;<b>Reglas</b></MDBNavLink></li>
            <li style={{ width: '100%', backgroundColor: 'rgb(240, 119, 6,0.0)' ,border: 0, color: 'white',paddingTop: '20%'}}><MDBNavLink style={{color: 'white'}}  to="/tranfer"><MDBIcon  icon="sync" />&nbsp;&nbsp;<b>Importación</b></MDBNavLink></li>
            <li style={{ width: '100%', backgroundColor: 'rgb(240, 119, 6,0.0)' ,border: 0, color: 'white',paddingTop: '20%'}}><MDBNavLink style={{color: 'white'}}  to="/Formulary"><MDBIcon  icon="clipboard-list" />&nbsp;&nbsp;<b>Formularios</b></MDBNavLink></li>
            <li style={{ width: '100%', backgroundColor: 'rgb(240, 119, 6,0.0)' ,border: 0, color: 'white',paddingTop: '20%'}}><MDBNavLink style={{color: 'white'}}  to="/options"><MDBIcon  icon="users-cog" />&nbsp;&nbsp;<b>Administrador</b></MDBNavLink></li>
            <li style={{ width: '100%', backgroundColor: 'rgb(240, 119, 6,0.0)' ,border: 0, color: 'white',paddingTop: '20%'}}><MDBNavLink style={{color: 'white'}}  to="/Docs"><MDBIcon  icon="book" />&nbsp;&nbsp;<b>Documentación</b></MDBNavLink></li>
        </ul>
    </div>
    </div>
    );
  }
}

export default Header;