import React, { Component } from "react";
import { TText } from "../form/index";
import {toast,ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { MDBCol,MDBRow,MDBModalBody,MDBIcon,MDBDataTable
    ,MDBModalFooter,MDBBtn,MDBModal,MDBModalHeader} from "mdbreact";
export class MaskFieldOptions extends Component {
    constructor(props){
        super(props)
        this.state = {
            searchData:[],
            data:[],
            modalOptions:'',
            arrayLength: [],
            loading:true,
            fields:[]
        }
        this.onKeyPressed = this.onKeyPressed.bind(this);
        this.verifyField = this.verifyField.bind(this);
        this.saveFieldConfig = this.saveFieldConfig.bind(this);
    }
    componentWillReceiveProps(){
        this.state.modalOptions = this.props.toggleOptions
    }
    async componentDidMount(){
        let array = []
        let data =  this.props.data
        try{
            /**Campos del Formulario Principal**/ 
            for (const key of data) {
                if(key['type'] === 'text'){
                    let element = {
                    fields: key['name'],
                    length: 
                    <div className="form-group">
                        <input type="number"
                        min="1"
                        onKeyPress={(e)=>this.onKeyPressed(e)}
                        onChange={(e)=>this.verifyField(e, key['name'])}
                        id={key['name']}
                        />
                    </div>
                    }
                    array.push(element)
                }
                this.state.loading = false;
            }
            /**Campos de los Grids**/ 
            for (const key of data) {
                if(key['type'] === 'grid'){
                    console.log(key['fields'], 'all grids')
                    for(const fields in  key['fields']){
                        if(key['fields'][fields]['type']=== 'text'){
                                let element = {
                                fields: key['fields'][fields]['name'],
                                length: 
                                <div className="form-group">
                                    <input type="number"
                                    min="1"
                                    onKeyPress={(e)=>this.onKeyPressed(e)}
                                    onChange={(e)=>this.verifyField(e, key['fields'][fields]['name']+'L')}
                                    id={key['fields'][fields]['name']+'L'}
                                    />
                                </div>
                                }
                                array.push(element)
                            }
                            this.state.loading = false;
                        }
                    }
                }   
        }catch(e){
            console.log(e)
        }
        const column = [
            {label:'ID',field:'ID',sort:'asc'},
            {label:'Campos',field:'Campos',sort:'asc'},
        ]
        const result = {columns:column, rows: array}
        this.setState({
            fields: result,
        })
    }
    onKeyPressed(e){
        if(e.key === 'e' || e.key=== '-' || e.key=== '+' || e.key === '.' ){
          e.preventDefault();
        }
      }
    verifyField(e,name){
        if(e.target.value.length > 4 || e.target.value===0){
            alert('El Campo solo acepta un valor hasta: 9999')
            document.getElementById(name).value = '';
        }
    } 
    saveFieldConfig(){
        this.state.arrayLength = []
        for(const i in this.props.data){
            try{
                let value 
                let field
            if(this.props.data[i]['type'] ==='text'){
                    value = document.getElementById(this.props.data[i]['name']).value
                    field = this.props.data[i]['name']
                    if(value===""){
                        this.state.arrayLength.push(field +'#'+null+ '#');
                    } else{
                        this.state.arrayLength.push(field +'#'+value.toString()+ '#');
                    }
                }
                                /* grids*/
                if(this.props.data[i]['type'] ==='grid'){
                    let inputNumber = ''
                    console.log('is grid',this.props.data[i])  
                    for(const x in this.props.data[i]['fields']){
                       if(this.props.data[i]['fields'][x]['type'] === 'text'){
                        console.log(this.props.data[i]['fields'][x]['name'],'ddd')
                        inputNumber = document.getElementById(this.props.data[i]['fields'][x]['name']+'L').value
                            if(inputNumber===""){
                                this.state.arrayLength.push(this.props.data[i]['fields'][x]['name']+'L' +'#'+null+ '#')
                                } else{
                                    this.state.arrayLength.push(this.props.data[i]['fields'][x]['name']+'L' +'#'+inputNumber+ '#');
                                }
                        console.log(this.state.arrayLength,'inputNumber!!!')
                        // inputNumber = document.getElementById(this.props.data[i]['fields'][x]['name']).value
                       }
                     }
                }
            }catch(e){
            }
        }
       this.props.saveFieldConfig(this.state.arrayLength)
       this.props.toggleFunction()
       toast.success('Configuracion guardada', {
            position: toast.POSITION.BOTTOM_CENTER
            })
        }
      render() {
            return( 
                <form>
                <ToastContainer/>
                <div className="form-group">
                <br/>
            
            <MDBModal size="lg" isOpen={this.props.toggleOptions} toggle={this.props.toggleFunction}>
              <MDBModalHeader class="text-center" toggle={this.props.toggleFunction}>
                  <b>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    &nbsp;&nbsp; &nbsp;
                    Asig. Longitud en Campos
                  </b>
              </MDBModalHeader>
              <MDBModalBody>
            <div>
            {this.state.loading? (<MDBIcon icon="spinner" spin size="3x" style={{color: '#00695c'}} fixed />):''}
                <br/>
                <MDBDataTable
                    paging={false}
                    striped
                    bordered
                    hover
                    data={this.state.fields}
                />
            </div>
              </MDBModalBody>
              <MDBModalFooter>
                <MDBBtn size="md" color="secondary" onClick={this.props.toggleFunction}>Cerrar</MDBBtn>
                <MDBBtn size="md" color="primary" onClick={this.saveFieldConfig}>Guardar</MDBBtn>
              </MDBModalFooter>
            </MDBModal>
                </div>
            </form>
            )
        } 
    } 
export default MaskFieldOptions; 
