import React from "react";
import { Route, Switch } from "react-router-dom";

import App from "./component/app/App";
import Rules from "./component/rules/rules";
import Page404 from "./component/404/Page404";
import Home from "./component/Home/home";
import MasksTable from "./component/Mask/masksTable";
import AllFormulary from "./component/formulary/allFormulary";
import RenderRules from './component/rules/renderRules';
import QR from './component/QR/QR';
import Mails from './component/Mails/Main';
import Admin from './component/admin/admi';
import RequireAuth from './component/RequireAuth/RequireAuth';
import Configurations from './component/formConfigurations/configurations';
import Price from './component/price/price';
import allPrice from './component/price/allPrice';
import Docs from './component/docs/docs';
import Tranfer from './component/transfer/tranfer';
import Users from './component/admin/users';
import Login from './component/login/login2';
import Test from './component/Test/test';
import OptionsAdmin from './component/admin/options';
import OptionsRules from './component/rules/rulesOptions'

const AppRoutes = () =>
<App>
    <Switch>
        <Route path="/Rules" component={Rules}/>
        <Route path="/testRule" component={Test}/>
        <Route path="/Docs" component={Docs}/>
        <Route path="/tranfer" component={Tranfer}/>
        <Route path="/Price" component={Price}/>
        <Route path="/allprices" component={allPrice}/>
        <Route path="/Mask" component={MasksTable}/>
        <Route path="/Formulary" component={RequireAuth(AllFormulary)}/>
        <Route path="/admin" component={Admin}/>
        <Route path="/options" component={OptionsAdmin}/>
        <Route path="/optionsRules" component={OptionsRules}/>
        <Route path="/render" component={RenderRules}/>
        <Route path="/Mails" component={Mails}/>
        <Route path="/QR" component={QR}/>
        <Route path="/Configurations" component={Configurations}/>
        <Route path="/users" component={Users}/>
        <Route path="/home" component={Home}/>
        <Route path="/login" component={Login}/>
        <Route  component={Login}/>
    </Switch>
</App>
export default AppRoutes