import React, { Component } from 'react';

import './Utilities.css'
class CardText extends Component {
    constructor(props){
        super(props)
        this.state ={
          isFS: this.props.fs
        }
    }
  render() {
      const {text, color} = this.props;
    return (
        <div   className={"CardText " + (this.state.isFS && " FieldSet")} style={{backgroundColor: color}}>
        {this.state.isFS? (
          <h6 id ={this.props.id}className="text-white">{text}</h6>
        ):
        (
          <h3 id ={this.props.id}className="text-white">{text}</h3>
        )
        }
        
        </div>
    );
  }
}

export default CardText;