import React, { Component } from 'react';
import { MDBListGroup, MDBListGroupItem, MDBContainer, MDBRow,MDBCol } from "mdbreact";
import Body from './body';


class Docs extends Component {

    constructor(props){
        super(props);

        this.state = {
            option: 0
        }

    }
  render() {
    let scroll = document.getElementById('scroll');
    if(scroll){
      scroll.scrollTop = 0
    }
    return (
      <div className="App">
        <MDBRow>
        <MDBCol size="3">
            <MDBListGroup style={{ width: "20rem" }}>
                <MDBListGroupItem  onClick={() => this.setState({option: 1})} className="cursor" active={this.state.option===1?true:false} >Crear formulario</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 2})} className="cursor" active={this.state.option===2?true:false}>Envio de  formulario al portal</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 3})} className="cursor" active={this.state.option===3?true:false}>Configuración de campos</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 4})} className="cursor" active={this.state.option===4?true:false}>Tipos de reglas de formulario</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 5})} className="cursor" active={this.state.option===5?true:false}>Reglas de formulario</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 6})} className="cursor" active={this.state.option===6?true:false}>Reglas de carga</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 7})} className="cursor" active={this.state.option===7?true:false}>Reglas de precios</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 8})} className="cursor" active={this.state.option===8?true:false}>Integración con QR</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 9})} className="cursor" active={this.state.option===9?true:false}>Mascaras</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 10})} className="cursor" active={this.state.option===10?true:false}>Errores comunes</MDBListGroupItem>
                <MDBListGroupItem  onClick={() => this.setState({option: 11})} className="cursor" active={this.state.option===11?true:false}>Configuración Previa a Envío de Formulario al Portal</MDBListGroupItem>
            </MDBListGroup>
        </MDBCol>
        <MDBCol size="9" id="scroll" className="scrollingDivDocs">
            <Body option={this.state.option}/>
        </MDBCol>
        </MDBRow>
      </div>
    );
  }
}

export default Docs;