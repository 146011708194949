import React from "react";
import { Doughnut } from "react-chartjs-2";
import { MDBContainer } from "mdbreact";

class ChartsForm extends React.Component {
state = {
  dataDoughnut: {
    labels: ["Softexpert", "BackEnd administrativo"],
    datasets: [
      {
        data: [this.props.se, this.props.bk],
        backgroundColor: ["#F7464A", "#283593"],
        hoverBackgroundColor: [
          "#b71c1c ",
          "#1a237e "
        ]
      }
    ]
  }
}


componentWillReceiveProps(props){
    this.state = {
        dataDoughnut: {
          labels: ["Softexpert", "BackEnd administrativo"],
          datasets: [
            {
              data: [props.se, props.bk],
              backgroundColor: ["rgb(240, 119, 6)", "#283593"],
              hoverBackgroundColor: [
                "rgb(240, 119, 6) ",
                "#1a237e "
              ]
            }
          ]
        }
      }
}

render() {
    return (
    <MDBContainer className="padding">
      <Doughnut data={this.state.dataDoughnut} options={{ responsive: true }} height={112} />
    </MDBContainer>
    );
  }
}

export default ChartsForm;