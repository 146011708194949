import React, { Component } from 'react';
import {  Form, Rules } from "../../data/index";
import config from '../../config';
import { MDBTable, MDBTableBody, MDBTableHead, MDBCol,MDBIcon,MDBModalFooter
    ,MDBModal,MDBModalHeader,MDBModalBody,MDBRow, MDBBtn, MDBContainer,MDBDataTable  } from "mdbreact";
import { toast ,ToastContainer} from 'react-toastify';
const {GetAllFormulary, GetFormularyById} = Form
const {GetRulesByForm} = Rules
class test extends Component {


    constructor(props){
        super(props)
        this.state = {
                data:[],
                loading: false
        }
    }



    async componentDidMount(){
        const datagetter = await GetAllFormulary();
       if(datagetter.ok){
        this.setState({
            data: this.table(datagetter.data.Formulary)
          })
       }
        
    }


    table(data){
       
        let array = []
            for (const i of data) {
              let element = {
                id: i.id,
                idForm: i.idForm,
                logo: <img className={" hoverable img-fluid"} width="80" src={i.url ? i.url: require('../../img/test.png')}/>,
                service: i.service_name,
                institution: i.institution_name,
                Accion: <MDBBtn  className="btn  btn-md text-white" color={config.color} onClick={()=>this.toggleImport(i.id)}><MDBIcon  icon="eyes" /> Ver Reglas</MDBBtn>
              }
              array.push(element)
            }
        const column = [
            {label:'id',field:'id',sort:'asc'},
            {label:'formulario',field:'formulario',sort:'asc'},
            {label:'logo',field:'logo',sort:'asc'},
            {label:'Servicio',field:'servicio',sort:'asc'},
            {label:'Institucion',field:'institucion',sort:'asc'},
            {label:'Accion',field:'Accion',sort:'asc'},
          ]

          const result = {columns:column, rows: array}
          return result
    }

   

    render() {
        return (
            
            <div className="App padding">
            <ToastContainer/>
            
            <h2>Pruebas de Reglas</h2><br/>
               <MDBContainer>
               {(this.state.data) && (this.state.data.length===0) &&
                   (<div className="col-md-12">
                       <MDBIcon icon="spinner" spin size="3x" style={{color: config.env==='production'? '#00695c': config.env==='development'? '#0d47a1': '#424242'}}fixed /><br></br>
                       <br></br>
                       <b><h3>Cargando...</h3></b>
                   </div>)
               }
               <MDBDataTable
                   bordered
                   hover
                   data={this.state.data}
               />
           </MDBContainer>
            </div>
        );
    }
}

export default test;